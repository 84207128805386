import React, { useState, useEffect } from "react";
import { HiPencil, HiTrash } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import HeaderSection from "../../../dashboard/sidebarpages/headerSection/HeaderSection";
import axios from "axios";
import { Base_URL } from "../../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AllSponsoredAdd = () => {
  const [sponsoredAds, setSponsoredAds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSponsoredAds = async () => {
      try  {
        const response = await axios.get(`${Base_URL}/sponsor-ads`);
        setSponsoredAds(response.data);
      } catch (error) {
        console.error(
          "Error fetching sponsored ads:",
          error.response ? error.response.data : error.message
        );
      }
    };

    fetchSponsoredAds();
  }, []);

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sponsoredAds.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(sponsoredAds.length / entriesPerPage);

  const filteredEntries = currentEntries.filter((ad) =>
    ad.created_by?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEditClick = (ad) => {
    navigate(`/admin/sponsoredAdd/edit/${ad.id}`, {
      state: {
        ...ad
      },
    });
  };

  const handleDeleteClick = async (id) => {
    try {
      await axios.delete(`${Base_URL}/sponsor-ads/${id}`);
      setSponsoredAds((prevAds) =>
        prevAds.filter((ad) => ad.id !== id)
      );
      toast.success("Sponsored ad deleted successfully.");
    } catch (error) {
      console.error(
        "Error deleting sponsored ad:",
        error.response ? error.response.data : error.message
      );
      toast.error("Failed to delete sponsored ad.");
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className='p-6 bg-gray-100 min-h-screen'>
      <HeaderSection
        title='All Sponsored Ads'
        breadcrumb={["Sponsored", "All Sponsored Ads"]}
      />

      <div className='bg-white p-6 rounded-lg shadow'>
        <div className='flex items-center justify-between mb-4 pb-2 border-b border-gray-200'>
          <div
            className='font-poppins'
            style={{
              color: "rgb(49, 42, 42)",
              fontSize: "18.5px",
              fontWeight: 600,
            }}
          >
            All Sponsored Ads
          </div>
          <button
            className='px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]'
            onClick={() => navigate("/admin/sponsoredAdd/add")}
          >
            + Add New
          </button>
        </div>

        <div className='flex items-center justify-between mb-4 pb-2'>
          <div className='flex items-center space-x-4'>
            <span className='text-sm text-gray-600 mr-2'>Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className='border border-gray-300 rounded'
            >
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
          </div>

          <div className='flex items-center space-x-4'>
            <span className='text-sm text-gray-600'>Search:</span>
            <input
              type='text'
              className='px-3 py-1 border border-gray-300 rounded'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                height: "30px",
                width: "200px",
                borderRadius: "5px",
                borderColor: "#d0d0d0",
                padding: "0 10px",
              }}
            />
          </div>
        </div>

        <div className='overflow-x-auto'>
          <table className='min-w-full bg-white'>
            <thead>
              <tr className='bg-white'>
                {[
                  "ID",
                  "Image",
                  "University ID",
                  "Page",
                  "Start Date",
                  "End Date",
                  "Total Count",
                  "Remaining",
                  "Created By",
                  "Status",
                  "Actions",
                ].map((heading) => (
                  <th
                    key={heading}
                    className='text-left border-b p-3'
                    style={{
                      color: "rgb(49, 42, 42)",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredEntries.length > 0 ? (
                filteredEntries.map((ad) => (
                  <tr key={ad.id} className='hover:bg-white hover:shadow-md'>
                    <td className='p-3'>{ad.id}</td>
                    <td className='p-3'>
                      <img 
                        src={`${Base_URL}${ad.image}`} 
                        alt="Ad" 
                        className='w-20 h-20 object-cover rounded'
                      />
                    </td>
                    <td className='p-3'>{ad.university_id}</td>
                    <td className='p-3'>{ad.page}</td>
                    <td className='p-3'>{formatDate(ad.date)}</td>
                    <td className='p-3'>{formatDate(ad.end_duration)}</td>
                    <td className='p-3'>{ad.total_count}</td>
                    <td className='p-3'>{ad.count_remaining}</td>
                    <td className='p-3'>{ad.created_by}</td>
                    <td className='p-3'>
                      <span className={`px-2 py-1 rounded ${ad.status === 1 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                        {ad.status === 1 ? 'Active' : 'Inactive'}
                      </span>
                    </td>
                    <td className='p-3'>
                      <div className='flex space-x-2'>
                        <button
                          onClick={() => handleEditClick(ad)}
                          className='p-2 rounded bg-cmsgreen text-white hover:bg-hovergreen'
                        >
                          <HiPencil />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(ad.id)}
                          className='p-1 rounded bg-red-500 text-white hover:bg-red-700'
                        >
                          <HiTrash size={18} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='11' className='text-center py-4'>
                    No sponsored ads found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className='flex justify-between items-center mt-4'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={handlePrevious}
              className='px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]'
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className='text-sm text-gray-600'>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className='px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]'
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AllSponsoredAdd;
