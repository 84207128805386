import React, { useState, useEffect } from "react";
import axios from "axios";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useParams, useNavigate } from "react-router-dom";
import { Base_URL } from "../../../apiConfig";
import HeaderSection from "../../../dashboard/sidebarpages/headerSection/HeaderSection";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const EditBranchSubContentForm = () => {
  const { title } = useParams();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [branchContentList, setBranchContentList] = useState([]);
  const decodedTitle = decodeURIComponent(title);
  const [formData, setFormData] = useState({
    branch_content_id: '',
    title: '',
    about: '',
    eligibility: '',
    job_role: '',
    work_description: '',
    salary: '',
    prep_books: '',
    pros: '',
    cons: '',
    path: '',
    branch_sub_content_url: [
      {
        title: '',
        url: '',
        video: ''
      }
    ]
  });

  useEffect(() => {
    const fetchSubBranchContent = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${Base_URL}/branch-sub-content/${decodedTitle}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        // Since the API returns an array with one item, we'll use the first item
        const contentData = response.data[0];
        
        if (!contentData) {
          toast.error('Content not found');
          return;
        }

        setFormData({
          id: contentData.id,
          branch_content_id: contentData.branch_contents_id,
          title: contentData.title,
          about: contentData.about || '',
          eligibility: contentData.eligibility || '',
          job_role: contentData.job_role || '',
          work_description: contentData.work_description || '',
          salary: contentData.salary || '',
          prep_books: contentData.prep_books || '',
          pros: contentData.pros || '',
          cons: contentData.cons || '',
          path: contentData.path || '',
          branch_sub_content_url: contentData.branch_sub_content_url?.length > 0 
            ? contentData.branch_sub_content_url 
            : [{ title: '', url: '', video: '' }]
        });

        // If you need to set branch content information
        setBranchContentList([{
          id: contentData.branch_contents_id,
          title: contentData.branch_content_title
        }]);

      } catch (error) {
        console.error('Error fetching sub branch content:', error);
        toast.error('Failed to load content details');
      } finally {
        setLoading(false);
      }
    };

    if (decodedTitle) {
      fetchSubBranchContent();
    }
  }, [decodedTitle, token]);

  // Rest of the component remains the same...
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleUrlChange = (index, field, value) => {
    const updatedUrls = [...formData.branch_sub_content_url];
    updatedUrls[index] = {
      ...updatedUrls[index],
      [field]: value
    };
    setFormData(prev => ({
      ...prev,
      branch_sub_content_url: updatedUrls
    }));
  };

  const addUrlField = () => {
    setFormData(prev => ({
      ...prev,
      branch_sub_content_url: [
        ...prev.branch_sub_content_url,
        { title: '', url: '', video: '' }
      ]
    }));
  };

  const removeUrlField = (index) => {
    if (formData.branch_sub_content_url.length > 1) {
      const updatedUrls = formData.branch_sub_content_url.filter((_, i) => i !== index);
      setFormData(prev => ({
        ...prev,
        branch_sub_content_url: updatedUrls
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.put(
        `${Base_URL}/branch-sub-content/${formData.id}`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      toast.success('Content updated successfully!');
      setTimeout(() => {
        navigate('/admin/career/AllBranchSubContent');
      }, 2000);
    } catch (error) {
      console.error('Error updating content:', error);
      toast.error(error.response?.data?.message || 'Failed to update content');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <HeaderSection title="Edit Branch Sub Content" breadcrumb={['Branch Sub Content', 'Edit Branch Sub Content']} />
      <div className="bg-white p-4 rounded-md">
        <h2 className="text-xl font-semibold mb-4">Edit Branch Sub Content</h2>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Branch Content */}
            <div className="flex flex-col">
              <label className="mb-2 font-medium text-sm">Branch Content</label>
              <input
                type="text"
                value={branchContentList.find(item => item.id === formData.branch_content_id)?.title || ''}
                className="border border-gray-300 p-2 rounded bg-gray-100"
                disabled
              />
            </div>

            {/* Title */}
            <div className="flex flex-col">
              <label htmlFor="title" className="mb-2 font-medium text-sm">Title</label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="border border-gray-300 p-2 rounded"
                required
              />
            </div>

            

            {/* About */}
            <div className="flex flex-col col-span-2">
              <label htmlFor="eligibility" className="mb-2 font-medium text-sm">About</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.about}
                onChange={(event, editor) => {
                  setFormData(prev => ({ ...prev, about: editor.getData() }));
                }}
              />
            </div>
            
            {/* Eligibility */}
            <div className="flex flex-col col-span-2">
              <label htmlFor="eligibility" className="mb-2 font-medium text-sm">Eligibility</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.eligibility}
                onChange={(event, editor) => {
                  setFormData(prev => ({ ...prev, eligibility: editor.getData() }));
                }}
              />
            </div>
            
            {/* Eligibility */}
            <div className="flex flex-col col-span-2">
              <label htmlFor="eligibility" className="mb-2 font-medium text-sm">Eligibility</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.eligibility}
                onChange={(event, editor) => {
                  setFormData(prev => ({ ...prev, eligibility: editor.getData() }));
                }}
              />
            </div>
            
            {/* salary*/}
            <div className="flex flex-col col-span-2">
              <label htmlFor="eligibility" className="mb-2 font-medium text-sm">Salary</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.salary}
                onChange={(event, editor) => {
                  setFormData(prev => ({ ...prev, salary: editor.getData() }));
                }}
              />
            </div>
            
            {/* Prep Books */}
            <div className="flex flex-col col-span-2">
              <label htmlFor="eligibility" className="mb-2 font-medium text-sm">Prep Books</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.prep_books}
                onChange={(event, editor) => {
                  setFormData(prev => ({ ...prev, prep_books: editor.getData() }));
                }}
              />
            </div>

            {/* Job Role */}
            <div className="flex flex-col col-span-2">
              <label htmlFor="job_role" className="mb-2 font-medium text-sm">Job Role</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.job_role}
                onChange={(event, editor) => {
                  setFormData(prev => ({ ...prev, job_role: editor.getData() }));
                }}
              />
            </div>

            {/* Work Description */}
            <div className="flex flex-col col-span-2">
              <label htmlFor="work_description" className="mb-2 font-medium text-sm">Work Description</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.work_description}
                onChange={(event, editor) => {
                  setFormData(prev => ({ ...prev, work_description: editor.getData() }));
                }}
              />
            </div>
            
            {/* pros */}
            <div className="flex flex-col col-span-2">
              <label htmlFor="eligibility" className="mb-2 font-medium text-sm">Pros</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.pros}
                onChange={(event, editor) => {
                  setFormData(prev => ({ ...prev, pros: editor.getData() }));
                }}
              />
            </div>
            
            {/* Cons*/}
            <div className="flex flex-col col-span-2">
              <label htmlFor="eligibility" className="mb-2 font-medium text-sm">Cons</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.cons}
                onChange={(event, editor) => {
                  setFormData(prev => ({ ...prev, cons: editor.getData() }));
                }}
              />
            </div>
            {/* Path*/}
            <div className="flex flex-col col-span-2">
              <label htmlFor="eligibility" className="mb-2 font-medium text-sm">Path</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.path}
                onChange={(event, editor) => {
                  setFormData(prev => ({ ...prev, path: editor.getData() }));
                }}
              />
            </div>

            {/* URLs Section */}
            <div className="col-span-2 mt-4">
              <label className="mb-2 font-medium text-sm block">URLs</label>
              {formData.branch_sub_content_url.map((urlField, index) => (
                <div key={index} className="flex gap-4 mb-4">
                  <input
                    type="text"
                    placeholder="Title"
                    value={urlField.title}
                    onChange={(e) => handleUrlChange(index, 'title', e.target.value)}
                    className="border border-gray-300 p-2 rounded flex-1"
                  />
                  <input
                    type="url"
                    placeholder="URL"
                    value={urlField.url}
                    onChange={(e) => handleUrlChange(index, 'url', e.target.value)}
                    className="border border-gray-300 p-2 rounded flex-1"
                  />
                  <input
                    type="url"
                    placeholder="Video URL"
                    value={urlField.video}
                    onChange={(e) => handleUrlChange(index, 'video', e.target.value)}
                    className="border border-gray-300 p-2 rounded flex-1"
                  />
                  <button
                    type="button"
                    onClick={() => removeUrlField(index)}
                    className="px-4 py-2 bg-red-500 text-white rounded-md"
                    disabled={formData.branch_sub_content_url.length === 1}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={addUrlField}
                className="px-4 py-2 bg-green-500 text-white rounded-md"
              >
                Add URL
              </button>
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md flex items-center justify-center"
            disabled={loading}
          >
            {loading ? (
              <div className="flex justify-center items-center">
                <svg
                  className="animate-spin h-5 w-5 text-white mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                Updating...
              </div>
            ) : (
              'Update'
            )}
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default EditBranchSubContentForm;