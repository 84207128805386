import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { sendAdminMessage, registerMessageCallback, unregisterMessageCallback, markMessagesAsRead, initializeAdminSocket } from '../../../../socket/adminChatService';
import { Chat_URL } from '../../../../apiConfig';



const ChatWindow = ({ user, onBack }) => {
  const [newMessage, setNewMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const chatAreaRef = useRef(null);
  const token = useSelector((state) => state.auth.token);
  const [socket, setSocket] = useState(null);

  // Initialize socket when component mounts
  useEffect(() => {
    if (!token) {
      console.error('❌ No token available for socket initialization');
      setError('Authentication required');
      return;
    }

    try {
      console.log('🔄 Initializing admin socket with token');
      const initializedSocket = initializeAdminSocket(token);
      
      if (!initializedSocket) {
        console.error('❌ Failed to initialize socket');
        setError('Failed to connect to chat server');
        return;
      }

      setSocket(initializedSocket);
      console.log('✅ Socket initialized successfully');
    } catch (error) {
      console.error('❌ Error initializing socket:', error);
      setError('Failed to connect to chat server');
    }
  }, [token]);

  useEffect(() => {
    console.log('👤 ChatWindow mounted/updated with user:', user);
    
    const loadMessages = async () => {
      try  {
        setIsLoading(true);
        
        // Determine the correct API endpoint based on user type
        const apiUrl = user.registration_id
          ? `${Chat_URL}/chat/messages/${user.registration_id}`
          : `${Chat_URL}/chat/messages/session/${user.session_id}`;

        console.log('🔄 Loading messages from:', apiUrl);
        
        const response = await fetch(apiUrl, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch messages');
        }

        const data = await response.json();
        console.log('📥 Received messages:', data);

        // Format messages for display
        const formattedMessages = (Array.isArray(data) ? data : [data])
          .filter(msg => msg && msg.message) // Filter messages with content
          .map(msg => ({
            id: msg.id,
            text: msg.message,
            sender: msg.sender_id === 2 ? 'admin' : 'user',
            timestamp: msg.created_on,
            delivered: msg.delivered === 1,
            session_id: msg.session_id,
            receiver_id: msg.receiver_id,
            sender_id: msg.sender_id
          }));

        console.log('✨ Formatted messages:', formattedMessages);
        setMessages(formattedMessages);
      } catch (error) {
        console.error('❌ Error loading messages:', error);
        setError('Failed to load messages');
      } finally {
        setIsLoading(false);
      }
    };

    // Load messages if we have a valid identifier
    if (user.registration_id || user.session_id) {
      loadMessages();
    }

    const identifier = user.registration_id || user.session_id;
    console.log('🔄 Registering chat callback for identifier:', identifier);
    
    // Register callback for new messages
    registerMessageCallback(identifier, (data) => {
      console.log('📨 Received data in chat window:', data);

      const formattedMessage = {
        id: data.id,
        text: data.message,
        sender: data.sender_id === 2 ? 'admin' : 'user',
        timestamp: data.created_on,
        delivered: data.delivered === 1,
        session_id: data.session_id,
        receiver_id: data.receiver_id,
        sender_id: data.sender_id
      };

      setMessages(prev => {
        const isDuplicate = prev.some(msg => 
          msg.id === formattedMessage.id || 
          (msg.text === formattedMessage.text && 
           Math.abs(new Date(msg.timestamp) - new Date(formattedMessage.timestamp)) < 1000)
        );
        
        if (isDuplicate) {
          console.log('🔄 Duplicate message detected, skipping:', formattedMessage);
          return prev;
        }

        console.log('✨ Adding new message to chat:', formattedMessage);
        return [...prev, formattedMessage];
      });
      
      scrollToBottom();
    });

    // Mark messages as read when chat window opens
    if (identifier) {
      markMessagesAsRead(identifier);
    }

    return () => {
      console.log('🧹 Cleaning up chat callbacks for identifier:', identifier);
      unregisterMessageCallback(identifier);
    };
  }, [user]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (chatAreaRef.current) {
      chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
    }
  };

  const handleSend = async (e) => {
    e.preventDefault();
    
    if (!newMessage.trim()) return;

    const timestamp = new Date().toISOString();
    const messageId = Date.now();

    // Create message data
    let messageData = {
      id: messageId,
      message: newMessage.trim(),
      sender_id: 2,  // Admin ID is always 2
      created_on: timestamp,
      delivered: 1
    };

    if (user.registration_id) {
      // For registered users
      messageData = {
        ...messageData,
        receiver_id: parseInt(user.registration_id),
        registration_id: parseInt(user.registration_id)
      };
    } else if (user.session_id) {
      // For unregistered users
      messageData = {
        ...messageData,
        session_id: user.session_id,
        sessionId: user.session_id
      };
    } else {
      console.error('❌ User has neither registration_id nor session_id:', user);
      setError('Cannot send message: Invalid user identification');
      return;
    }

    try {
      // Create UI message
      const uiMessage = {
        id: messageId,
        text: messageData.message,
        sender: 'admin',
        timestamp: timestamp,
        delivered: true,
        session_id: messageData.session_id,
        receiver_id: messageData.receiver_id,
        sender_id: messageData.sender_id,
        registration_id: messageData.registration_id
      };

      // Add message to UI immediately
      setMessages(prev => [...prev, uiMessage]);
      
      setNewMessage('');
      scrollToBottom();

      console.log('📤 Admin message sent:', {
        messageData,
        uiMessage
      });

      // Send message through socket
      if (socket) { // Ensure socket is initialized
        socket.emit('sendMessage', messageData, (response) => {
          if (response.error) {
            console.error('❌ Failed to send message through socket:', response.error);
            setError('Failed to send message');
          } else {
            console.log('✅ Message sent successfully:', response);
          }
        });
      } else {
        console.error('❌ Socket is not initialized');
        setError('Socket not connected');
      }
    } catch (error) {
      console.error('❌ Error handling message:', error);
      setError('Failed to handle message');
    }
  };

  const formatTime = (timestamp) => {
    try {
      return new Date(timestamp).toLocaleTimeString([], { 
        hour: '2-digit', 
        minute: '2-digit'
      });
    } catch (e) {
      return '';
    }
  };
  return (
    <div className="flex flex-col h-[600px]">
      {/* Header */}
      <div className="h-16 bg-white px-4 border-b flex items-center">
        <div className="flex items-center space-x-3">
          <div className="w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center text-white font-semibold">
            {user.name?.[0]?.toUpperCase() || 'U'}
          </div>
          <div>
            <h2 className="font-semibold text-gray-900">{user.name}</h2>
            <p className="text-sm text-gray-500">{user.mobile_number || 'No number'}</p>
          </div>
        </div>
      </div>

      {/* Chat Area */}
      <div 
        ref={chatAreaRef}
        className="flex-1 overflow-y-auto p-4 space-y-4 bg-gray-50"
      >
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        ) : error ? (
          <div className="text-center text-red-500 bg-red-50 p-4 rounded-lg">
            {error}
          </div>
        ) : messages.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-full text-center p-8">
            <div className="text-gray-400 mb-4">
              <svg className="w-16 h-16 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
              </svg>
            </div>
            <h3 className="text-xl font-medium text-gray-900 mb-2">No Messages Yet</h3>
            <p className="text-gray-500">Start the conversation by sending a message</p>
          </div>
        ) : (
          messages.map((msg) => (
            <div
              key={msg.id}
              className={`flex ${msg.sender === 'admin' ? 'justify-end' : 'justify-start'} animate-fade-in mb-2`}
            >
              {msg.sender !== 'admin' && (
                <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center mr-2">
                  <span className="text-sm font-medium text-gray-600">
                    {user.name?.[0]?.toUpperCase() || 'U'}
                  </span>
                </div>
              )}
              <div
                className={`max-w-[70%] rounded-lg p-3 ${
                  msg.sender === 'admin' 
                    ? 'bg-blue-500 text-white' 
                    : 'bg-gray-200 text-gray-900'
                }`}
              >
                <p className="break-words whitespace-pre-wrap">{msg.text}</p>
                <div className={`flex items-center justify-end space-x-1 mt-1 text-xs ${
                  msg.sender === 'admin' ? 'text-blue-100' : 'text-gray-500'
                }`}>
                  {formatTime(msg.timestamp)}
                  {msg.sender === 'admin' && (
                    <span>
                      {msg.status === 'sending' && '• Sending...'}
                      {msg.status === 'sent' && '• ✓'}
                      {msg.status === 'pending' && '• ⚠️'}
                      {msg.status === 'failed' && '• ❌'}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Message Input - Fixed at bottom */}
      <div className="h-16 bg-white border-t px-4 py-2 mt-auto">
        <form onSubmit={handleSend} className="flex items-center h-full space-x-2">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type a message..."
            className="flex-1 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 bg-gray-50"
          />
          <button
            type="submit"
            disabled={!newMessage.trim()}
            className={`p-2 rounded-lg ${
              newMessage.trim()
                ? 'bg-blue-500 text-white hover:bg-blue-600'
                : 'bg-gray-100 text-gray-400 cursor-not-allowed'
            } transition-colors`}
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
            </svg>
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatWindow;