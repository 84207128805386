import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../../apiConfig";
import { useSelector } from "react-redux";
import Select from "react-select";

const EditExam = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const exam = location.state;
  console.log("exam", exam);

  // Transform the program_master array from the response into the format required by react-select
  const initialPrograms = exam?.program_master?.map((program) => ({
    value: program.program_master_id,
    label: program.program_master_name,
  })) || [];

  const [formData, setFormData] = useState({
    exam_master_name: exam?.exam_master_name || "",
    program_master_id: initialPrograms, // Set initial value as an array of objects
  });

  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState([]);

  // Retrieve token from Redux store
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    // Fetch program data from the API
    const fetchPrograms = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/program_master`);
        const programArray = response.data.map((program) => ({
          value: program.id,
          label: program.program_master_name,
        }));
        setPrograms(programArray);
      } catch (error) {
        console.error("Failed to fetch programs:", error);
        toast.error("Failed to load program options.");
      }
    };

    fetchPrograms();
  }, []);

  const handleProgramChange = (selectedOptions) => {
    setFormData((prevData) => ({
      ...prevData,
      program_master_id: selectedOptions, // Update with the selected options
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Transform the selected programs into an array of IDs before sending the request
      const payload = {
        ...formData,
        program_master_id: formData.program_master_id.map((program) => program.value),
      };
  
      await axios.put(`${Base_URL}/exam-master/${exam.exam_master_id}`, payload, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`, // Add token authorization
        },
        withCredentials: true, // Enable withCredentials for the request
      });
      toast.success("Exam updated successfully!");
      setTimeout(() => {
        navigate("/admin/exams/allExams");
      }, 2000);
    } catch (error) {
      toast.error("Failed to update exam.");
      console.error(
        "Failed to update exam:",
        error.response?.data || error.message
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="bg-gray-100 p-6 h-screen">
        <HeaderTitle mainTitle="Exams" subTitle="Edit Exam" />
        <div className="bg-white p-4 rounded-md">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label htmlFor="exam" className="mb-2 font-medium text-sm">
                  Exam Name
                </label>
                <input
                  type="text"
                  id="exam"
                  name="exam_master_name"
                  value={formData.exam_master_name}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="program_master_id"
                  className="mb-2 font-medium text-sm"
                >
                  Select Programs
                </label>
                <Select
                  isMulti
                  options={programs}
                  value={formData.program_master_id} // Set the value to the transformed programs
                  onChange={handleProgramChange}
                  placeholder="Select Programs"
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            </div>

            <button
              type="submit"
              className="mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen"
            >
              {loading ? (
                <div className="flex justify-center items-center">
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Submitting...
                </div>
              ) : (
                "Update Exam"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditExam;