import React, { useEffect, useState } from 'react';
import { FaBell, FaCog, FaMoon, FaSearch, FaUserCircle } from 'react-icons/fa';
import MenuIcon from '../../../../dashboard/components/navbar/MenuIcon';
import SlidingTab from '../../../../dashboard/components/chat/SlidingTab';
import { registerNotificationCallback } from '../../../../socket/adminChatService';
import { useSelector } from 'react-redux';

const Navbar = ({ toggleSidebar, isSidebarOpen }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [hasUnread, setHasUnread] = useState(false);
  const { registration_id } = useSelector(state => state.auth);

  useEffect(() => {
    if (registration_id === 2) {
      // Register for notifications
      registerNotificationCallback((notification) => {
        console.log('🔔 New notification received:', notification);
        setNotifications(prev => [notification, ...prev]);
        setHasUnread(true);
        
        // Show browser notification
        if (Notification.permission === 'granted') {
          new Notification(notification.title, {
            body: notification.message,
            icon: '/path/to/notification-icon.png' // Add your notification icon path
          });
        }
      });

      // Request notification permission
      if (Notification.permission === 'default') {
        Notification.requestPermission();
      }
    }
  }, [registration_id]);

  const toggleChatTab = () => {
    setIsChatOpen(!isChatOpen);
    if (!isChatOpen) {
      setHasUnread(false);
    }
  };

  return (
    <div className={`h-16 bg-white text-gray-700 flex items-center px-4 border-b border-gray-300 fixed top-0 left-0 right-0 z-10 ${isSidebarOpen ? 'ml-64' : 'ml-20'}`}>
      <div className="flex-grow flex items-center justify-between">
        {/* Sidebar Toggle Button */}
        <button onClick={toggleSidebar} className="flex items-center">
          <MenuIcon isCollapsed={!isSidebarOpen} />
        </button>

        {/* Search Bar */}
        <div className="hidden md:flex items-center flex-grow">
          <div className="relative w-full max-w-md">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              <FaSearch className="text-gray-500" />
            </div>
            <input
              type="text"
              placeholder="Search"
              className="pl-10 w-full bg-gray-200 rounded-full border-none focus:ring-0 outline-none text-sm font-normal"
              style={{ fontFamily: 'Poppins, sans-serif' }}
            />
          </div>
        </div>

        {/* Right Section Icons */}
        <div className="flex items-center space-x-4 ml-4">
          <button className="hidden md:flex items-center">
            <FaMoon className="w-5 h-5 text-[#41B923]" />
          </button>
          <button className="hidden md:flex items-center relative" onClick={toggleChatTab}>
            <FaBell className="w-5 h-5 text-[#41B923]" />
            {hasUnread && (
              <span className="absolute -top-1 -right-1 w-2 h-2 bg-red-500 rounded-full"></span>
            )}
          </button>
          <button className="hidden md:flex items-center">
            <FaCog className="w-5 h-5 text-[#41B923]" />
          </button>
          <button className="hidden md:flex items-center">
            <FaUserCircle className="w-6 h-6 text-[#41B923]" />
          </button>
        </div>
      </div>
      <SlidingTab isOpen={isChatOpen} onClose={toggleChatTab} />
    </div>
  );
};

export default Navbar;
