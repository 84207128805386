import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Calendar, Clock, X, MessageCircle } from 'lucide-react';
import Educational_Loan from '../assets/StudentDash/Educational_Loan.webp';
import Refer_a_Friend from '../assets/StudentDash/Refer_a_Friend.webp';
import Schedule_a_call from '../assets/StudentDash/Schedule_a_call.webp';
import whatsapp from '../assets/StudentDash/whatsapp.webp';
import ReferEarnModal from '../components/Header/Modal/ReferEarnModal';
import MobileReferEarn from '../components/Header/MobileReferEarn';
import { Base_URL } from '../apiConfig';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';

const StudentDashboard = ({ onCompleteProfile }) => {
  const [isReferEarnModalOpen, setReferEarnModalOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [scheduledDate, setScheduledDate] = useState("");
  const [buttonText, setButtonText] = useState('Schedule Call');
  const token = useSelector((state) => state.auth.token);
  const userName = token?.user?.name || token?.name || 'Student';
  const navigate = useNavigate();

  // Helper function to pad numbers with leading zeros
  const padNumber = (num) => num.toString().padStart(2, '0');
  const clearForm = () => {
    setScheduledDate("");
    setSelectedTime("");
    setButtonText('Schedule Call');
    setLoading(false);
  };
  // Function to format date and time to required format
  const formatDateTime = (date, time) => {
    if (!date || !time) return null;
    
    // Split time into hours and minutes
    const [hours, minutes] = time.split(':');
    
    // Create a new date object
    const dateTime = new Date(date);
    dateTime.setHours(parseInt(hours, 10));
    dateTime.setMinutes(parseInt(minutes, 10));
    dateTime.setSeconds(0);
    
    // Format to YYYY-MM-DD HH:mm:ss
    const year = dateTime.getFullYear();
    const month = padNumber(dateTime.getMonth() + 1);
    const day = padNumber(dateTime.getDate());
    const formattedHours = padNumber(dateTime.getHours());
    const formattedMinutes = padNumber(dateTime.getMinutes());
    
    return `${year}-${month}-${day} ${formattedHours}:${formattedMinutes}:00`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedDateTime = formatDateTime(scheduledDate, selectedTime);

    if (!formattedDateTime) {
      toast.error("Please select both date and time.");
      return;
    }

    setLoading(true);
    setButtonText('Call Scheduled');

    try {
      const response = await axios.post(
        `${Base_URL}/student/book-call`,
        { scheduled_at: formattedDateTime },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Check if the response status is 200
      if (response.status === 200) {
        // Show success toast
        toast.success("Call scheduled successfully!");
        
        // Clear form fields and close modal immediately
        setScheduledDate("");
        setSelectedTime("");
        setIsChatOpen(false);
        
        // Reset button text after 2 seconds
        setTimeout(() => {
          setLoading(false);
          setButtonText('Schedule Call');
        }, 2000);
      }
    } catch (error) {
      console.error("Error scheduling call:", error);
      // Show error toast with specific message if available
      toast.error(error.response?.data?.message || "Failed to schedule call. Please try again.");
      
      // Reset states immediately on error
      setLoading(false);
      setButtonText('Schedule Call');
    }
  };
  

  // Generate available time slots (8 AM to 8:30 PM, half-hourly)
  const generateTimeSlots = () => {
    const times = [];
    for (let hour = 8; hour <= 20; hour++) {
      times.push(
        `${padNumber(hour)}:00`,
        `${padNumber(hour)}:30`
      );
    }
    return times;
  };

  // Content array for the cards
  const cardContent = [
    {
      heading: 'Education Loan',
      paragraph: 'Explore flexible education loan options tailored to your needs. Check your eligibility and apply for financial assistance seamlessly.',
      footer: 'Explore Loans',
      image: Educational_Loan,
    },
    {
      heading: 'Schedule a Call',
      paragraph: 'Need personalized guidance? Schedule a call with our experts to help you make the right decisions for your academic journey.',
      footer: 'Call Us',
      actionType: 'call',
      actionValue: '7889761896',
      image: Refer_a_Friend,
    },
    {
      heading: 'Refer and Earn',
      paragraph: 'Invite friends to join and earn rewards! Spread the word about our platform and get exclusive benefits for every successful referral.',
      footer: 'Start Referring',
      actionType: 'refer',
      actionValue: null,
      image: Schedule_a_call,
    },
    {
      heading: 'Connect on Whatsapp',
      paragraph: 'Have questions or need instant support? Connect with our team on WhatsApp for quick assistance anytime, anywhere.',
      footer: 'Chat Now',
      actionType: 'whatsapp',
      actionValue: '7889761896',
      image: whatsapp,
    },
  ];

  const handleFooterClick = (actionType, actionValue) => {
    if (actionType === 'call') {
      window.location.href = `tel:${actionValue}`; // Open phone dialer
    } else if (actionType === 'whatsapp') {
      window.open(`https://wa.me/${actionValue}`, '_blank'); // Open WhatsApp chat
    } else if (actionType === 'refer') {
      openReferEarnModal(); // Open the Refer & Earn modal
    }
  };

  const openReferEarnModal = () => {
    setReferEarnModalOpen(true);
  };

  const closeReferEarnModal = () => {
    setReferEarnModalOpen(false);
  };

  return (
    <div className="flex flex-col space-y-4">
      {/* Top Section */}
      <div className="w-full h-auto bg-white border border-black/25 shadow-md rounded-md flex flex-col sm:flex-row items-center justify-between p-4 gap-4">
        <h2 className="text-center sm:text-left text-base sm:text-lg md:text-xl font-semibold text-black">
          Hey {userName}, Your Profile is Incomplete!
        </h2>
        <button 
          className="w-full sm:w-auto bg-[#459A07] text-white px-4 py-2 rounded-md font-medium hover:opacity-90" 
          onClick={onCompleteProfile}
        >
          Complete Your Profile
        </button>
      </div>

      {/* Cards Section */}
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 w-full">
        {cardContent.map((card, index) => (
          <div key={index} className="w-full bg-white border border-black/25 shadow-md rounded-md flex flex-col sm:flex-row gap-4 p-4">
            {/* Image Section */}
            <div className="w-full sm:w-[250px] h-[150px] bg-gray-300 rounded-md flex-shrink-0 overflow-hidden" aria-hidden="true">
              <img src={card.image} alt="No Image" className="w-full h-full object-cover" />
            </div>

            {/* Content Section */}
            <div className="flex flex-col justify-between flex-grow">
              <h3 className="text-lg font-semibold">{card.heading}</h3>
              <p className="text-sm text-gray-600 leading-relaxed">{card.paragraph}</p>
              <button 
                className="text-xs font-medium text-[#3ACA2E] text-left"
                onClick={() => handleFooterClick(card.actionType, card.actionValue)}
              >
                {card.footer}
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Chat Widget */}
      <div
        className="fixed bottom-5 right-6 z-[1001] md:bottom-6 md:right-2"
        style={{ zIndex: 1001 }}
      >
        {/* Chat bubble button */}
        <button
          onClick={() => {
            if (isChatOpen) {
              // Clear form when closing
              clearForm();
            }
            setIsChatOpen(!isChatOpen);
          }}
          className={`w-14 h-14 rounded-full bg-[#459A07] text-white shadow-lg flex items-center justify-center transform transition-transform duration-300 hover:scale-110 ${
            isChatOpen ? 'scale-90' : ''
          }`}
        >
          {isChatOpen ? (
            <X size={24} />
          ) : (
            <MessageCircle size={24} />
          )}
        </button>

        {/* "Book a Call" text */}
        <div className="mt-2 text-center text-sm text-[#459A07] font-bold">
          <span className="animate-pulse">Book a Call</span>
        </div>

        {/* Schedule form */}
        <div
          className={`absolute bottom-20 right-0 w-80 bg-white rounded-lg shadow-xl transform transition-all duration-300 ${
            isChatOpen
              ? 'translate-y-0 opacity-100'
              : 'translate-y-4 opacity-0 pointer-events-none'
          }`}
          style={{
            zIndex: 1002,
            borderRadius: '12px',
            padding: '10px',
            animation: 'borderMove 3s linear infinite',
          }}
        >
          <div className="p-6">
            <h3 className="text-lg font-semibold mb-4 text-gray-800">Schedule a Call</h3>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Select Date</label>
                <div className="relative">
                  <Calendar
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    size={18}
                  />
                  <input
                    type="date"
                    value={scheduledDate}
                    onChange={(e) => setScheduledDate(e.target.value)}
                    min={new Date().toISOString().split("T")[0]}
                    className="w-full pl-10 pr-4 py-2 border rounded-md focus:ring-2 focus:ring-[#459A07] focus:border-transparent"
                    required
                  />
                </div>
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Select Time
                </label>
                <div className="relative">
                  <Clock
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    size={18}
                  />
                  <select
                    value={selectedTime}
                    onChange={(e) => setSelectedTime(e.target.value)}
                    className="w-full pl-10 pr-4 py-2 border rounded-md focus:ring-2 focus:ring-[#459A07] focus:border-transparent"
                    required
                  >
                    <option value="">Select time</option>
                    {generateTimeSlots().map((time) => (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <button
                type="submit"
                disabled={loading}
                className="w-full bg-[#459A07] text-white py-2 rounded-md font-medium hover:opacity-90 transform transition-transform duration-300 hover:scale-105"
              >
                {buttonText}
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Refer & Earn Modals */}
      {isReferEarnModalOpen && (
        <>
          {/* For Mobile View */}
          <div className="sm:hidden mt-5">
            <MobileReferEarn isOpen={isReferEarnModalOpen} onRequestClose={closeReferEarnModal} />
          </div>

          {/* For Larger Screens */}
          <div className="hidden sm:block">
            <ReferEarnModal isOpen={isReferEarnModalOpen} onRequestClose={closeReferEarnModal} />
          </div>
        </>
      )}
    </div>
  );
};

export default StudentDashboard;