
import React, { useEffect, useState } from "react";
import "./App.css";
import AppRoutes from "./routes";
import Layout from "./Layout";
import PopupModal from "./pages/PopupModal";
import { useLocation } from "react-router-dom";
import SocialMediaIcons from "./components/SocialMediaIcons";
import ChatWidget from "./pages/ChatWidget";
import { useSelector } from "react-redux";
import { initializeAdminSocket, disconnectAdminSocket } from "./socket/adminChatService";

function App() {
  const [isPopupModalOpen, setIsPopupModalOpen] = useState(false);
  const location = useLocation();
  // const isAdminRoute = location.pathname.startsWith('/admin');
  
  const { registration_id } = useSelector(state => state.auth);

  // Initialize admin socket when registration_id is 2
  useEffect(() => {
    if (registration_id === 2) {
      console.log('🔄 Admin user detected - Initializing socket...');
      initializeAdminSocket(localStorage.getItem('token'));
      
      // Cleanup on unmount
      return () => {
        console.log('👋 Cleaning up admin socket connection...');
        disconnectAdminSocket();
      };
    }
  }, [registration_id]);

  useEffect(() => {
    // Check if current route is /dashboard or /admin, if so, skip showing the popup
    if (location.pathname === "/dashboard" || location.pathname === "/admin") {
      return; // Don't show the popup on these pages
    }
    const popupSubmitted = localStorage.getItem("popupSubmitted");
    const popupClosed = sessionStorage.getItem("popupClosed");

    // Show the popup if it has not been submitted or closed in this session
    if (!popupSubmitted && !popupClosed) {
      const timer = setTimeout(() => {
        setIsPopupModalOpen(true);
      }, 3000);

      // Cleanup timeout on component unmount
      return () => clearTimeout(timer);
    }
  }, [location]);

  const handlePopupClose = () => {
    sessionStorage.setItem("popupClosed", "true");
    setIsPopupModalOpen(false);
  };

  const handleFormSubmit = () => {
    localStorage.setItem("popupSubmitted", "true");
    setTimeout(() => {
      setIsPopupModalOpen(false);
    }, 2000);
  };

  return (
    <>
      <Layout>
        <SocialMediaIcons />
        <AppRoutes />
      </Layout>
      <PopupModal
        isOpen={isPopupModalOpen}
        onClose={handlePopupClose}
        onSubmit={handleFormSubmit}
      />

     {/* {!isAdminRoute && <ChatWidget />} */}
    </>
  );
}

export default App;
