import React, { useEffect, useRef, useState } from 'react';

import ChatWindow from './ChatWindow';
import { initializeSocket, disconnectSocket } from '../../../socket/chatService';
import { useSelector } from 'react-redux';
import UserListSection from '../../../Admin/dashboard/components/chat/UserListSection';

const SlidingTab = ({ isOpen, onClose }) => {
  const tabRef = useRef();
  const [activeTab, setActiveTab] = useState("Chats");
  const [selectedUser, setSelectedUser] = useState(null);
  const socketRef = useRef(null);
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    if (isOpen && user) {
      socketRef.current = initializeSocket({
        userId: user.id,
        name: user.name,
        type: 'admin'
      });
    }

    return () => {
      disconnectSocket();
    };
  }, [isOpen, user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tabRef.current && !tabRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "hidden";
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "";
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "";
    };
  }, [isOpen, onClose]);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setActiveTab("Chats");
  };

  const handleBack = () => {
    setSelectedUser(null);
  };

  const renderContent = () => {
    if (selectedUser) {
      return <ChatWindow user={selectedUser} socket={socketRef.current} onBack={handleBack} />;
    }
    
    switch (activeTab) {
      case "Notes":
        return <div>Notes Component</div>;
      case "Alerts":
        return <div>Alerts Component</div>;
      case "Chats":
      default:
        return <UserListSection onUserSelect={handleUserSelect} socket={socketRef.current} />;
    }
  };

  return (
    <div
      ref={tabRef}
      className={`fixed top-0 right-0 h-screen bg-white shadow-lg transform ${
        isOpen ? "translate-x-0" : "translate-x-full"
      } transition-transform duration-300 ease-in-out w-[250px] md:w-[300px] lg:w-[350px]`}
    >
      <div className="bg-gray-200 p-4">
        <div className="flex justify-around text-[#6173fa] text-sm font-semibold" style={{ fontFamily: "Poppins, sans-serif" }}>
          {["Notes", "Alerts", "Chats"].map((tab) => (
            <div
              key={tab}
              className={`relative cursor-pointer pb-2 ${
                activeTab === tab
                  ? "text-[#3945f8] after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[2px] after:bg-[#3945f8] after:transition-all after:duration-300 after:ease-in-out"
                  : "hover:text-[#3945f8] after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[2px] after:bg-[#3945f8] after:scale-x-0 after:transition-all after:duration-300 after:ease-in-out hover:after:scale-x-100"
              }`}
              style={{
                transition: "color 0.3s ease-in-out",
              }}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </div>
          ))}
        </div>
      </div>
      <div className="h-full">
        {renderContent()}
      </div>
    </div>
  );
};

export default SlidingTab;


