import io from 'socket.io-client';
import { Chat_URL } from '../apiConfig';

let socket = null;
let notificationCount = 0;
let onMessageCallback = null;
let onlineStatusCallback = null;

// Storage helper functions
export const saveMessageToStorage = (message) => {
  try {
    const storedMessages = sessionStorage.getItem('chatMessages');
    const messages = storedMessages ? JSON.parse(storedMessages) : [];
    messages.push(message);
    sessionStorage.setItem('chatMessages', JSON.stringify(messages));
  } catch (error) {
    console.error('❌ Error saving message to storage:', error);
  }
};

export const loadMessagesFromStorage = () => {
  try {
    const storedMessages = sessionStorage.getItem('chatMessages');
    return storedMessages ? JSON.parse(storedMessages) : [];
  } catch (error) {
    console.error('❌ Error loading messages from storage:', error);
    return [];
  }
};

export const initializeSocket = async (userData) => {
  console.log('🔄 Initializing socket with user data:', userData);

  if (socket?.connected) {
    console.log('✅ Socket already connected');
    return socket;
  }

  try {
   
    let socketUrl = `${Chat_URL}/chat`;
    let queryParams = {
      receiverId: '2'  // Admin ID
    };

    // For unregistered users, try to restore previous session
    if (userData.type !== 'Registered User') {
      const storedSessionId = sessionStorage.getItem('chatSessionId');
    if (storedSessionId) {
        console.log('🔄 Restoring previous session:', storedSessionId);
        queryParams.sessionId = storedSessionId;
      }
    }

    if (userData.type === 'Registered User') {
      console.log('👤 Setting up connection for registered user:', userData.id);
      queryParams.senderId = userData.id;
    } else {
      console.log('👥 Setting up connection for unregistered user');
      queryParams.name = userData.name;
      queryParams.mobile_number = userData.mobile_number;
    }

    // Initialize socket connection with query params
    console.log('🔌 Connecting to socket with params:', queryParams);
    socket = io(socketUrl, {
      query: queryParams,
      transports: ['websocket']
    });

    // Set up connection event handlers
  socket.on('connect', () => {
    console.log('✅ Connected to WebSocket Server:', socket.id);
      if (onlineStatusCallback) {
        onlineStatusCallback(true);
      }

      // For unregistered users, request missed messages after connection
      if (userData.type !== 'Registered User') {
        const sessionId = sessionStorage.getItem('chatSessionId');
        if (sessionId) {
          console.log('📥 Requesting missed messages for session:', sessionId);
          socket.emit('getMissedMessages', { sessionId });
        }
      }

      // Load stored messages from session storage for unregistered users
      if (userData.type !== 'Registered User') {
        const storedMessages = loadMessagesFromStorage();
        if (storedMessages.length > 0 && onMessageCallback) {
          console.log('📤 Loading stored messages:', storedMessages);
          storedMessages.forEach(msg => onMessageCallback(msg));
        }
      }
    });

    socket.on('receiveMessage', (data) => {
      console.log('📩 Received message:', data);
      
      // Skip empty array messages
      if (Array.isArray(data) && data.length === 0) {
        return;
      }

      // For unregistered users, check if the received data is a session ID string
      if (userData.type !== 'Registered User' && typeof data === 'string') {
        console.log('💾 Received and storing session ID:', data);
        sessionStorage.setItem('chatSessionId', data);
        
        // Update socket query params with session ID
        socket.io.opts.query = {
          ...socket.io.opts.query,
          sessionId: data
        };

        // Request missed messages for new session
        socket.emit('getMissedMessages', { sessionId: data });
        return;
      }

      // Handle missed messages array
      if (Array.isArray(data)) {
        console.log('📥 Processing missed messages:', data);
        data.forEach(msg => {
          // Ensure we have all required fields
          const formattedMessage = {
            id: msg.id || Date.now(),
            text: msg.message || msg.text || '',
            sender: msg.sender_id === 2 ? 'admin' : 'user',
            timestamp: msg.created_on || msg.timestamp || new Date().toISOString(),
            sessionId: msg.session_id || msg.sessionId,
            registration_id: msg.registration_id,
            delivered: msg.delivered
          };
          
          if (userData.type !== 'Registered User') {
            saveMessageToStorage(formattedMessage);
          }

          if (onMessageCallback) {
            onMessageCallback(formattedMessage);
          }
        });
        return;
      }

      // Handle normal message data
      if (typeof data === 'object') {
        const formattedMessage = {
          id: data.id || Date.now(),
          text: data.message || data.text || '',
          sender: data.sender_id === 2 ? 'admin' : 'user',
          timestamp: data.created_on || data.timestamp || new Date().toISOString(),
          sessionId: data.session_id || data.sessionId,
          registration_id: data.registration_id,
          delivered: data.delivered
        };

        // Save message to storage for unregistered users
        if (userData.type !== 'Registered User') {
          saveMessageToStorage(formattedMessage);
        }

        if (onMessageCallback) {
          onMessageCallback(formattedMessage);
        }
        
        // Increment notification count if window is not focused
        if (!document.hasFocus()) {
          notificationCount++;
          updateNotificationBadge();
        }
      }
    });

    socket.on('connect_error', (error) => {
      console.error('❌ Socket connection error:', error);
      if (onlineStatusCallback) {
        onlineStatusCallback(false);
      }
    });

    socket.on('disconnect', () => {
      console.log('❌ Disconnected from WebSocket Server');
      socket = null;
      if (onlineStatusCallback) {
        onlineStatusCallback(false);
      }
  });
  
  return socket;
  } catch (error) {
    console.error('❌ Failed to initialize socket:', error);
    throw error;
  }
};

export const sendMessage = (message) => {
  if (!socket?.connected) {
    console.error('❌ Socket not connected');
    return false;
  }

  try {
    const messageData = {
      message,
      receiverId: '2' // Always send to admin
    };

    // Add appropriate parameters based on user type
    const isRegistered = socket.io.opts.query.senderId;
    if (isRegistered) {
      messageData.senderId = socket.io.opts.query.senderId;
    } else {
      const sessionId = sessionStorage.getItem('chatSessionId');
      if (!sessionId) {
        console.error('❌ No session ID available yet. Please wait for session creation.');
        return false;
      }
      messageData.sessionId = sessionId;

      // Save user message to storage for unregistered users
      const userMessage = {
        id: Date.now(),
        text: message,
        sender: 'user',
        timestamp: new Date().toISOString()
      };
      saveMessageToStorage(userMessage);
    }

    console.log('📤 Sending message:', messageData);
    socket.emit('sendMessage', messageData);
    return true;
  } catch (error) {
    console.error('❌ Error sending message:', error);
    return false;
  }
};

export const setMessageCallback = (callback) => {
  onMessageCallback = callback;
};

export const setOnlineStatusCallback = (callback) => {
  onlineStatusCallback = callback;
};

export const getNotificationCount = () => {
  return notificationCount;
};

export const resetNotificationCount = () => {
  notificationCount = 0;
  updateNotificationBadge();
};

const updateNotificationBadge = () => {
  // Update favicon or any other notification indicator
  const favicon = document.querySelector('link[rel="shortcut icon"]');
  if (favicon && notificationCount > 0) {
    // You can implement custom favicon with notification count
    // or use any other notification indicator
  }
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
    onMessageCallback = null;
    onlineStatusCallback = null;
  }
};

export const loadChatHistory = async (identifier) => {
  if (!socket) {
    console.error('Socket not initialized');
    return [];
  }

  return new Promise((resolve) => {
    console.log('Requesting chat history for:', identifier);
    socket.emit('get_chat_history', { identifier }, (response) => {
      console.log('Received chat history:', response);
      resolve(response.messages || []);
    });
  });
};

export const linkMessagesToUser = async (guestId, userId) => {
  try {
    const response = await fetch('https://api.collegedwarka.com/chat/link-messages', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ guestId, userId })
    });
    if (!response.ok) throw new Error('Failed to link messages');
    return await response.json();
  } catch (error) {
    console.error('Error linking messages:', error);
  }
};
