import React, { useState,useEffect } from "react";
import { FiUpload } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/slice/authSlice";
import { useNavigate } from "react-router-dom";



function DigitalPartner() {
  const [toggle, setToggle] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    userName: "",
    phoneNo: "",
  });

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { error } = useSelector((state) => state.auth);

  // Password validation rules
  const isPasswordValid =
    formData.password.length >= 8 &&
    /\d/.test(formData.password) &&
    /[!@#$%^&*(),.?":{}|<>]/.test(formData.password);

  const isPasswordMatch =
    formData.password &&
    formData.confirmPassword &&
    formData.password === formData.confirmPassword;

  // Phone number validation
  const isPhoneValid = (phone) => {
    const phoneRegex = /^[6-9]\d{9}$/;
    return phoneRegex.test(phone);
  };

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Validate all fields
  const validateForm = () => {
    if (!formData.userName.trim()) {
      setErrorMessage("Please enter a username");
      return false;
    }
    if (!isPhoneValid(formData.phoneNo)) {
      setErrorMessage("Please enter a valid 10-digit phone number");
      return false;
    }
    if (!formData.email.trim()) {
      setErrorMessage("Please enter an email address");
      return false;
    }
    if (!isPasswordValid) {
      setErrorMessage("Password must be at least 8 characters long, include a number and a special character");
      return false;
    }
    if (!isPasswordMatch) {
      setErrorMessage("Passwords do not match");
      return false;
    }
    return true;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    if (!validateForm()) {
      // If validation fails, don't proceed with API call
      return;
    }

    setLoading(true);

    const data = {
      email: formData.email,
      password: formData.password,
      name: formData.userName,
      mobilenumber: formData.phoneNo,
      reg_type: 2,
    };

    // dispatch(loginUser({ formData: data, actionType: 1 }))
    // navigate("/certified-partner-dashboard/profile");
    try {
      await dispatch(loginUser({ formData: data, actionType: 1 })).unwrap();
      navigate("/certified-partner-dashboard/profile");
    } catch (error) {
      setErrorMessage(error.response.data.message|| "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  // Effect to handle API error
  useEffect(() => {
    if (error) {
      setErrorMessage(error);
      setLoading(false);
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [error]);
  return (
    <div className="bg-white font-sans-serif min-h-screen flex flex-col items-center px-4 md:px-8 lg:px-16">
      {/* Top Text */}
      <h1 className="text-center text-[28px] text-textGray  md:text-[30px] lg:text-[40px] font-bold leading-[1.2] mt-8 md:mt-12 lg:mt-[70px] w-[100%] lg:max-w-[684px]">
      Partner with Us!
      </h1>

      {/* Subheading */}
      <p className="text-center text-[16px] md:text-[20px] lg:text-[24px] font-medium leading-[1.3] mt-6 md:mt-8 lg:mt-[50px] max-w-[90%] lg:max-w-[1100px]">
      Together, we can innovate, grow, and achieve remarkable milestones.
      </p>

      {/* Main Form Container */}
      <div
        className="bg-white shadow-xl border md:mb-12 rounded-lg p-6 md:p-8 lg:p-12 mt-6 md:mt-8  lg:mb-[130px]  w-full max-w-[900px] mx-auto"
        
      >
        {/* Form Heading */}
        <h2 className="text-center text-[18px] md:text-[20px] lg:text-[22px] font-bold leading-[1.3] mb-6 md:mb-8">
          Provide the Required Details
        </h2>

        {/* Form */}
        <form onSubmit={handleSubmit} >

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
             {/* Column 1 */}
          
            <div>
              <label className="block mb-2 text-[16px] md:text-[18px] font-medium">Full Name</label>
              <input
                type="text"
                className="w-full p-2 border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
                placeholder="Enter your full name"
                name='userName'
                value={formData.userName}
                onChange={handleInputChange}
               
              />
            </div>
            <div>
              <label className="block mb-2 text-[16px] md:text-[18px] font-medium">Email Address</label>
              <input
               type='email'
               name='email'
               placeholder='Email'
               value={formData.email}
               onChange={handleInputChange}
                className="w-full p-2 border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
              
               
              />
            </div>
           
            <div>
              <label className="block mb-2 text-[16px] md:text-[18px] font-medium">Mobile Number</label>
              <input
               id='phoneNo'
               type='tel'
               name='phoneNo'
               placeholder='Mobile Number'
               value={formData.phoneNo}
               onChange={handleInputChange}
                className="w-full p-2 border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
                
                
              />
            </div>
              <div>
              <label className="block mb-2 text-[16px] md:text-[18px] font-medium">Password</label>
              <input
                type='password'
                name='password'
                placeholder='Password'
                value={formData.password}
                onChange={handleInputChange}
                className="w-full p-2 border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
                
                
              />
            </div>
           
            <div>
              <label className="block mb-2 text-[16px] md:text-[18px] font-medium">Confirm Password</label>
              <input
                 type='password'
                 name='confirmPassword'
                 placeholder='Confirm Password'
                 value={formData.confirmPassword}
                 onChange={handleInputChange}
                className="w-full p-2 border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
               
                
              />
            </div>
          

         
          </div>
         
        

        {/* Toggle Button Section */}
        <div className="mt-8 flex flex-col items-center gap-4">
          <div className="flex items-center gap-4">
            <button
              className={`relative max-[410px]:w-[118px] w-[100px]  md:w-[56px] lg:w-[50px] h-[24px] rounded-full flex items-center px-1 transition-colors ${
                toggle ? "bg-[#3ACA2E]" : "bg-gray-300"
              }`}
              onClick={(e) => {
                e.preventDefault();
                setToggle(!toggle);
              }}
            >
              <span
                className={`w-[20px] h-[20px] bg-white rounded-full transition-transform transform ${
                  toggle ? "translate-x-6" : "translate-x-0"
                }`}
              ></span>
            </button>
            <span className="text-[14px] md:text-[16px] font-medium">
              By Proceeding ahead you expressly agree to CollegeDwarka{" "}
              <span className="text-[#3ACA2E] cursor-pointer">Terms & Conditions</span> and{" "}
              <span className="text-[#3ACA2E] cursor-pointer">Privacy Policy</span>.
            </span>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="bg-[#3ACA2E] text-white font-medium w-[300px] h-[40px] rounded-lg mt-6"
          >
            Submit
          </button>
           {/* Error Message */}
           {errorMessage && (
              <div className='mt-2 text-red-500 text-sm text-center'>
                {errorMessage}
              </div>
            )}
        </div>
        </form>
      </div>
    </div>
  );
}

export default DigitalPartner;
