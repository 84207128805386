import io from 'socket.io-client';
import { Base_URL, Chat_URL } from '../apiConfig';

let socket = null;
let messageCallbacks = new Map();
let onlineStatusCallbacks = new Map();
let notificationCountCallback = null;
let userListCallback = null;
let notificationCallback = null;
let unreadCountCallback = null;
let reconnectAttempts = 0;
const MAX_RECONNECT_ATTEMPTS = 5;
const RECONNECT_DELAY = 1000;
const ADMIN_ID = 2; // Hardcoded admin registration_id

// Helper function to determine if a user is registered based on their ID format
const isRegisteredUser = (identifier) => !identifier.includes('-');

export const initializeAdminSocket = (adminToken) => {
  if (!adminToken) {
    console.error('❌ No admin token provided');
    return null;
  }

  try {
    // If socket exists and is connected, return it
    if (socket?.connected) {
      console.log('✅ Admin socket already connected:', socket.id);
      return socket;
    }

    // Clean up existing socket if it exists
    if (socket) {
      console.log('🧹 Cleaning up existing socket connection');
      socket.removeAllListeners();
      socket.disconnect();
      socket = null;
    }

    const socketUrl = `${Chat_URL}/chat/admin`;
    const queryParams = {
      token: adminToken,
      receiverId: '2'
    };

    console.log('🔌 Connecting admin socket with params:', queryParams);

    socket = io(socketUrl, {
      query: queryParams,
      transports: ['websocket'],
      reconnection: true,
      reconnectionAttempts: MAX_RECONNECT_ATTEMPTS,
      reconnectionDelay: RECONNECT_DELAY,
      timeout: 10000
    });

    // Set up connection event handlers
    socket.on('connect', () => {
      if (socket) {
        console.log('✅ Admin connected to WebSocket Server:', socket.id);
        reconnectAttempts = 0;
      }
    });

    socket.on('connect_error', (error) => {
      console.error('❌ Admin socket connection error:', error);
      handleReconnection();
    });

    socket.on('disconnect', (reason) => {
      console.log('❌ Admin disconnected from WebSocket Server:', reason);
      handleReconnection();
    });

    socket.on('error', (error) => {
      console.error('❌ Socket error:', error);
    });

    socket.on('receiveMessage', (data) => {
      console.log('📩 Admin received message:', data);
      
      // Skip if we received an empty array
      if (Array.isArray(data) && data.length === 0) {
        console.log('⚠️ Received empty message array, skipping');
        return;
      }

      // Handle both single message and message array
      const messages = Array.isArray(data) ? data : [data];
      
      messages.forEach(msgData => {
        // Extract user identifier (either sessionId or registration_id)
        const userId = msgData.registration_id || msgData.sessionId;
        if (userId && messageCallbacks.has(userId)) {
          // Format the message for the UI
          const formattedMessage = {
            id: msgData.id || Date.now(),
            text: msgData.message || msgData.text || '',
            sender: msgData.sender_id === ADMIN_ID ? 'admin' : 'user',
            timestamp: msgData.created_on || msgData.timestamp || new Date().toISOString(),
            sessionId: msgData.sessionId,
            registration_id: msgData.registration_id
          };
          
          console.log('✨ Formatted incoming message:', formattedMessage);
          messageCallbacks.get(userId)(formattedMessage);
        } else {
          console.warn('⚠️ No callback found for user:', userId);
        }
      });
    });

    // Handle user online/offline status
    socket.on('userOnline', (data) => {
      console.log('👤 User online:', data);
      const userId = data.sessionId || data.registration_id;
      if (userId && onlineStatusCallbacks.has(userId)) {
        onlineStatusCallbacks.get(userId)(true);
      }
    });

    socket.on('userOffline', (data) => {
      console.log('👤 User offline:', data);
      const userId = data.sessionId || data.registration_id;
      if (userId && onlineStatusCallbacks.has(userId)) {
        onlineStatusCallbacks.get(userId)(false);
      }
    });

    // Handle chat history
    socket.on('chatHistory', (data) => {
      console.log('📩 Received chat history:', data);
      
      if (!Array.isArray(data) || data.length === 0) {
        console.log('ℹ️ No chat history available');
        return;
      }

      // Get the user ID from the first message
      const firstMessage = data[0];
      const userId = firstMessage.sessionId || firstMessage.registration_id;

      if (userId && messageCallbacks.has(userId)) {
        // Format and send all messages
        const formattedMessages = data.map(msg => ({
          id: msg.id || Date.now(),
          text: msg.message || msg.text || '',
          sender: msg.sender_id === ADMIN_ID ? 'admin' : 'user',
          timestamp: msg.created_on || msg.timestamp || new Date().toISOString(),
          sessionId: msg.sessionId,
          registration_id: msg.registration_id
        }));

        console.log('✨ Formatted chat history:', formattedMessages);
        messageCallbacks.get(userId)({ type: 'history', messages: formattedMessages });
      }
    });

    // Handle unread count updates
    socket.on('unreadCount', (data) => {
      console.log('📬 Received unread count update:', data);
      if (unreadCountCallback) {
        unreadCountCallback(data);
      }
    });

    return socket;
  } catch (error) {
    console.error('❌ Failed to initialize admin socket:', error);
    return null;
  }
};

export const sendAdminMessage = async (messageData) => {
  console.log('🔍 Starting message send process...');
  console.log('📝 Message data received:', messageData);
  
  if (!socket) {
    console.error('❌ Socket is null - not initialized');
    return false;
  }

  try {
    // Validate required fields
    if (!messageData.message) {
      console.error('❌ Message text is missing in messageData');
      return false;
    }

    // Format message for sending
    const formattedMessage = {
      message: messageData.message,
      sender_id: 2, // Admin ID
      receiver_id: messageData.receiver_id,
      registration_id: messageData.registration_id,
      created_on: messageData.created_on || new Date().toISOString(),
      delivered: 1
    };

    return await emitMessage(formattedMessage);
  } catch (error) {
    console.error('❌ Unexpected error in sendAdminMessage:', error);
    return false;
  }
};

const emitMessage = (messageData) => {
  return new Promise((resolve) => {
    let isResolved = false;
    const timeoutId = setTimeout(() => {
      if (!isResolved) {
        console.warn('⚠️ Message send timed out after 5s');
        isResolved = true;
        resolve(false);
      }
    }, 5000);

    console.log('📤 Emitting message with data:', messageData);
    socket.emit('sendMessage', messageData, (response) => {
      if (isResolved) return;
      clearTimeout(timeoutId);
      isResolved = true;

      if (response?.success) {
        console.log('✅ Message sent successfully');
        resolve(true);
      } else {
        console.error('❌ Server returned error:', response);
        resolve(false);
      }
    });

    socket.once('error', (error) => {
      if (!isResolved) {
        console.error('❌ Socket error while sending message:', error);
        clearTimeout(timeoutId);
        isResolved = true;
        resolve(false);
      }
    });
  });
};

export const receiveMessage = (data) => {
  console.log('📩 Admin received message:', data);
  
  // Skip empty array messages
  if (Array.isArray(data) && data.length === 0) {
    console.log('⚠️ Received empty message array, skipping');
    return;
  }

  // Handle array of messages (chat history)
  if (Array.isArray(data)) {
    console.log('📥 Processing message array:', data);
    data.forEach(msg => {
      const identifier = msg.registration_id || msg.sessionId;
      const callback = messageCallbacks.get(identifier);
      
      if (callback) {
        callback(msg);
      } else {
        console.warn('⚠️ No callback found for identifier:', identifier);
      }
    });
    return;
  }

  // Extract user identifier (registration_id or sessionId)
  const identifier = data.registration_id || data.sessionId;
  if (!identifier) {
    console.error('❌ No identifier found in message:', data);
    return;
  }

  // Format message for UI
  const formattedMessage = {
    id: data.id || Date.now(),
    text: data.message || data.text || '',
    sender: data.sender_id === 2 ? 'admin' : 'user',
    timestamp: data.created_on || data.timestamp || new Date().toISOString(),
    sessionId: data.sessionId,
    registration_id: data.registration_id
  };

  // Get callback for this user
  const callback = messageCallbacks.get(identifier);
  if (callback) {
    console.log('✅ Invoking callback for identifier:', identifier);
    callback(formattedMessage);
  } else {
    console.warn('⚠️ No callback found for identifier:', identifier);
  }
};

export const loadUserChatHistory = async (identifier) => {
  if (!socket?.connected) {
    console.error('❌ Socket not connected');
    return [];
  }

  return new Promise((resolve) => {
    console.log('🔄 Requesting chat history for:', identifier);
    socket.emit('getChatHistory', { identifier }, (response) => {
      console.log('📥 Received chat history response:', response);
      
      const messages = response?.messages || [];
      const formattedMessages = messages.map(msg => ({
      id: msg.id || Date.now(),
      text: msg.message || msg.text || '',
        sender: msg.sender_id === ADMIN_ID ? 'admin' : 'user',
        timestamp: msg.created_on || msg.timestamp || new Date().toISOString(),
        sessionId: msg.sessionId,
        registration_id: msg.registration_id
      }));

      resolve(formattedMessages);
    });
  });
};

export const markMessagesAsRead = async (identifier) => {
  if (!socket?.connected) {
    console.error('❌ Socket not connected');
    return false;
  }

  try {
    console.log('📬 Marking messages as read for:', identifier);
    socket.emit('markMessagesAsRead', { identifier });
    
    // Update unread count locally
    if (unreadCountCallback) {
      unreadCountCallback(prev => ({
        ...prev,
        [identifier]: 0
      }));
    }
    
    return true;
  } catch (error) {
    console.error('❌ Error marking messages as read:', error);
    return false;
  }
};

const handleReconnection = () => {
  if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
    reconnectAttempts++;
    const delay = RECONNECT_DELAY * Math.pow(2, reconnectAttempts);
    console.log(`🔄 Attempting reconnection in ${delay}ms...`);
    setTimeout(() => {
      if (socket) socket.connect();
    }, delay);
  } else {
    console.error('❌ Max reconnection attempts reached');
  }
};

// Callback registration functions
export const registerMessageCallback = (userId, callback) => {
  messageCallbacks.set(userId, callback);
};

export const unregisterMessageCallback = (userId) => {
  messageCallbacks.delete(userId);
};

export const registerOnlineStatusCallback = (userId, callback) => {
  onlineStatusCallbacks.set(userId, callback);
};

export const unregisterOnlineStatusCallback = (userId) => {
  onlineStatusCallbacks.delete(userId);
};

export const setNotificationCountCallback = (callback) => {
  notificationCountCallback = callback;
};

export const setUnreadCountCallback = (callback) => {
  unreadCountCallback = callback;
};

export const registerNotificationCallback = (callback) => {
  notificationCallback = callback;
};

export const disconnectAdminSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
    messageCallbacks.clear();
    onlineStatusCallbacks.clear();
    notificationCountCallback = null;
    unreadCountCallback = null;
    notificationCallback = null;
  }
};