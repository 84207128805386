import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Base_URL } from "../../../apiConfig";
import HeaderSection from "../../../dashboard/sidebarpages/headerSection/HeaderSection";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const EditSubCareerForm = () => {
  const { title } = useParams();
  const decodedTitle = decodeURIComponent(title).replace(/-/g, " "); // Convert hyphens to spaces

  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    title: "",
    stream_id: "",
    stream_name: "",
    created_by: 1,
  });

  useEffect(() => {
    const fetchSubCareerDetails = async () => {
      try {
        const formattedTitle = decodedTitle.replace(/-/g, " "); // Convert 'Computer-Science' to 'Computer Science'
        
        const response = await axios.get(`${Base_URL}/stream-course`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const subCareers = response.data;
  
        // Find the sub-career that matches the formatted title
        const subCareerData = subCareers.find((item) => item.title === formattedTitle);
  
        if (subCareerData) {
          setFormData({
            id: subCareerData.id,
            title: subCareerData.title,
            stream_id: subCareerData.stream_id,
            stream_name: subCareerData.stream_name,
            created_by: subCareerData.created_by || 1,
          });
        } else {
          toast.error("Sub Career not found.");
        }
      } catch (error) {
        console.error("Error fetching sub career details:", error);
        toast.error("Failed to load sub career details.");
      }
    };
  
    fetchSubCareerDetails();
  }, [decodedTitle, token]);

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const updateData = {
        title: formData.title,
        stream_id: formData.stream_id,
        created_by: formData.created_by,
      };

      await axios.put(`${Base_URL}/stream-course/${formData.id}`, updateData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      toast.success("Sub Career updated successfully!");
      setTimeout(() => {
        navigate("/admin/career/AllSubCareerForm");
      }, 2000);
    } catch (error) {
      console.error("Error updating sub career:", error);
      toast.error(error.response?.data?.message || "Failed to update sub career");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <HeaderSection title="Edit Career" breadcrumb={["Career", "Edit Career"]} />
      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
          <div className="font-poppins text-[18.5px] font-semibold text-[#312a2a]">
            Edit Career
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col">
              <label className="text-sm font-medium text-gray-700 mb-1">Stream</label>
              <input
                type="text"
                value={formData.stream_name}
                className="border border-gray-300 rounded-md p-2 bg-gray-100 cursor-not-allowed"
                disabled
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="title" className="text-sm font-medium text-gray-700 mb-1">
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2 focus:ring-cmsgreen focus:border-cmsgreen"
                required
              />
            </div>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-cmsgreen text-white rounded-md hover:bg-hovergreen transition-colors duration-200"
            >
              {loading ? "Updating..." : "Update Career"}
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditSubCareerForm;
