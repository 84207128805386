import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { parseDocument } from "htmlparser2";
import { DomHandler } from "domhandler";
import { DomUtils } from "htmlparser2";

import { Base_URL, Img_URL } from "../apiConfig";
import HatCircleLoader from "../components/Loader/HatCircleLOader";

// Function to parse HTML content
const parseHTMLContent = (html) => {
  const handler = new DomHandler();
  const doc = parseDocument(html, { lowerCaseTags: true });
  return DomUtils.getInnerHTML(doc);
};

const NewsDetail = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleNewsClick = (news_title) => {
    const formattedTitle = news_title.trim().replace(/\s+/g, "-");
    navigate(`/particularNews/${formattedTitle}`);
  };

  useEffect(() => {
    const fetchNews = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Base_URL}/get/news_announcements`);

        const sortedNews = response.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        setNews(sortedNews);
      } catch (error) {
        console.error("Error fetching news data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  const getParagraphs = (content) => {
    const paragraphs = content.split(". ");
    const firstTwo = paragraphs.slice(0, 2).join(". ") + ".";
    const lastTwo = paragraphs.slice(-2).join(". ") + ".";
    return { firstTwo, lastTwo };
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? "Invalid Date"
      : date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        });
  };

  if (loading) return <HatCircleLoader />;
  if (!news.length) return <p className="text-center mt-4">No news available.</p>;

  return (
    <div className="w-full">
      {/* Header */}
      <div
        className="w-full bg-[rgba(0,0,0,0.5)] mt-[40px] py-5"
        style={{ border: "1px solid #00000040" }}
      >
        <h1 className="text-center text-white text-4xl font-bold">ALL NEWS</h1>
      </div>

      {/* News Content */}
      <div className="w-full p-6 grid grid-cols-1 md:grid-cols-3 gap-6">
        {news.map((item) => {
          const { lastTwo } = getParagraphs(item.content);

          return (
            <div
              key={item.id}
              onClick={() => handleNewsClick(item.title)}
              className="bg-[#FAFAFA] p-6 rounded-lg border border-[#00000040] shadow-md cursor-pointer"
            >
              {/* Title */}
              <h1 className="text-lg font-bold line-clamp-2">{item.title}</h1>

              {/* Date */}
              <p className="text-gray-500 text-xs mt-2">
                Created on: {formatDate(item.created_at)}
              </p>

              {/* Image */}
              {item.picture ? (
                <div className="my-4">
                  <img
                    src={`${Img_URL}/files${item.picture}`}
                    alt={item.title}
                    className="w-full h-[200px] object-cover rounded-md"
                  />
                </div>
              ) : (
                <div className="my-4">
                  <div className="w-full h-[200px] bg-gray-300 rounded-md"></div>
                </div>
              )}

              {/* Last two paragraphs (Parsed HTML) */}
              <div
                className="text-gray-700 font-[600] leading-relaxed text-sm line-clamp-3"
                dangerouslySetInnerHTML={{
                  __html: parseHTMLContent(lastTwo),
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NewsDetail;
