import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Base_URL } from '../../../apiConfig';
import CourseCard from './CourseCard/CourseCard';
import HatCircleLoader from '../../Loader/HatCircleLOader';
import nodata from "../../../assets/landing/no_data.png";


const CourseDetail = () => {
  const { university_id, id } = useParams();
  const [courseDetail, setCourseDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourseDetail = async () => {
      setLoading(true)
   
      const university_name=university_id.replace(/-/g, " " );
      const program_name=id.replace(/-/g, " ").toLowerCase()
      try {
        if (!university_id || !id) {
          throw new Error("Missing required parameters");
        }

        const response = await fetch(`${Base_URL}/courses/name/university/${university_name}/program/${program_name}`);
        
        if (!response.ok) {
          throw new Error("Failed to fetch course details");
        }

        const data = await response.json();
        
        if (!data || data.length === 0) {
          throw new Error("No course data found");
        }

        setCourseDetail(data);
      } catch (err) {
        console.error("Error fetching course details:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseDetail();
  }, [university_id, id]);

  const handleViewMore = (courseId) => {
    navigate(`/subcourse/${university_id}/${courseId}`); // Navigate to the course details page
  };

  if (loading) return <HatCircleLoader/>;
  if (error) return (
    <div className="flex-grow p-6 mt-20 bg-white h-[80vh]  flex flex-col justify-center items-center">
      <img src={nodata} alt="Error occurred" className="w-[200px] h-auto mb-4" />
      <div className="text-black-700 p-6 max-w-lg text-center">
        <p>{error}</p>
      </div>
    </div>
  );

  if (!courseDetail.length) return (
    <div className="flex flex-col items-center justify-center">
      <img src={nodata} alt="No data available" className="w-6 h-6 mb-4" />
      <p>No course details available.</p>
    </div>
  );

  return (
    <>
      
      <div className="w-full text-center py-8" style={{ marginTop: "40px" }}>
        <h1 className="text-3xl font-bold text-[#002147]">Course Details</h1>
      </div>

      <div className='mx-auto p-6 '>
  <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6'>
    {courseDetail.map((course) => (
      <CourseCard
        key={course.university_programs_id}
        course_count={course.course_count}
        exams_accepted={course.course_exams_accepted}
        program_master_name={course?.course_master_name || 'N/A'}
        max_fees={course?.max_fees}    
        min_fees={course?.min_fees} 
        program_duration={course?.course_duration || 'N/A'}
        study_mode={course?.study_mode || 'N/A'}
        eligibility={course?.eligibility || 'N/A'}
        id={course?.id}
        course_master_id={course?.course_master_id}
        course_details={course?.course_details}
        university_id={course?.university_id}
        onViewMore={handleViewMore} // Pass the handleViewMore function
        title={'Expert Help'}
      />
    ))}
  </div>
</div>

      
    </>
  );
};

export default CourseDetail;