import React, { Suspense, lazy } from "react";
import { useLocation } from "react-router-dom";

// Lazy load the Navbar and Footer components
const Navbar = lazy(() => import("./components/Header/Navbar"));
const Footer = lazy(() => import("./components/Footer/Footer"));
const BottomNavigation = lazy(() =>
  import("./components/Header/BottomNavigation")
);

const Layout = ({ children }) => {
  const location = useLocation();

  // Define routes where the header should be excluded
  const excludedRoutes = [
    "/admin",
    "/dashboard",
    "/certified-partner-dashboard",
    "/authentication"
  ];

  // Check if the current route starts with any excluded route
  const isExcludedRoute = excludedRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  // Check specifically for student-dashboard route to exclude bottom navigation
  const isStudentDashboard = location.pathname.startsWith("/student-dashboard");

  return (
    <div>
      {/* Conditionally render the header */}
      {!isExcludedRoute && <Navbar />}

      {/* Main content */}
      <main>{children}</main>

      {/* Bottom navigation - exclude for student-dashboard and other excluded routes */}
      {!isExcludedRoute && !isStudentDashboard && <BottomNavigation />}

      {/* Footer can also be conditionally rendered */}
      {!isExcludedRoute && <Footer />}
    </div>
  );
};

export default Layout;