import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import ChatWindow from "./ChatWindow";
import { registerMessageCallback, unregisterMessageCallback } from "../../../../socket/adminChatService";
import { Chat_URL } from "../../../../apiConfig";

const UserListSection = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const hasMore = useRef(true);
  const observer = useRef();
  const [selectedUser, setSelectedUser] = useState(null);
  const [error, setError] = useState(null);

  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchUsersOnce = async () => {
      if (users.length > 0 || isLoading) return; // Skip if we already have users or are loading
      setIsLoading(true);

      try  {
        const response = await fetch(
          `${Chat_URL}/chat/users`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch users");

        const data = await response.json();
        console.log('📥 Received users:', data);
        
        setUsers(data.sort((a, b) => {
          const timeA = new Date(a.last_message_time || 0);
          const timeB = new Date(b.last_message_time || 0);
          return timeB - timeA;
        }));
      } catch (error) {
        console.error("❌ Error fetching users:", error);
        setError("Failed to load users. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      fetchUsersOnce();
    }
  }, [token]); // Only depend on token

  // Add message received handler
  useEffect(() => {
    const handleNewMessage = (data) => {
      console.log('📩 New message received in UserList:', data);
      setUsers(prevUsers => {
        const updatedUsers = [...prevUsers];
        const userIndex = updatedUsers.findIndex(u => 
          (u.session_id && u.session_id === data.sessionId) ||
          (u.registration_id && u.registration_id === data.registration_id)
        );

        if (userIndex !== -1) {
          // Update existing user
          updatedUsers[userIndex] = {
            ...updatedUsers[userIndex],
            last_message_time: new Date().toISOString(),
            last_message: data.text || data.message,
            unread_count: (updatedUsers[userIndex].unread_count || 0) + 1
          };
        } else {
          // Add new user
          updatedUsers.unshift({
            session_id: data.sessionId,
            registration_id: data.registration_id,
            name: data.name || "Anonymous",
            mobile_number: data.mobile_number,
            last_message_time: new Date().toISOString(),
            last_message: data.text || data.message,
            unread_count: 1
          });
        }

        // Sort users by latest message
        return updatedUsers.sort((a, b) => {
          const timeA = new Date(a.last_message_time || 0);
          const timeB = new Date(b.last_message_time || 0);
          return timeB - timeA;
        });
      });
    };

    // Register message callback
    if (token) {
      registerMessageCallback('userList', handleNewMessage);
    }

    return () => {
      if (token) {
        unregisterMessageCallback('userList');
      }
    };
  }, [token]);

  const handleUserClick = async (user) => {
    console.log("🔄 Selected user:", user);
    setError(null);
    
    try {
      const identifier = user.registration_id || user.session_id;
      if (!identifier) {
        throw new Error('No valid identifier found for user');
      }

      // Update unread count for clicked user
      setUsers(prevUsers => 
        prevUsers.map(u => 
          (u.session_id === user.session_id || u.registration_id === user.registration_id)
            ? { ...u, unread_count: 0 }
            : u
        )
      );

      // Fetch chat history using the appropriate API endpoint
      const apiUrl = user.registration_id
        ? `${Chat_URL}/chat/messages/${user.registration_id}`
        : `${Chat_URL}/chat/messages/session/${user.session_id}`;

      console.log('🔄 Fetching chat history from:', apiUrl);

      const response = await fetch(apiUrl, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) throw new Error('Failed to fetch chat history');

      const chatHistory = await response.json();
      console.log('📥 Raw chat history:', chatHistory);

      const chatData = {
        id: identifier,
        name: user.chat_session_name || user.name || "Anonymous",
        mobile_number: user.chat_session_mobile_number || user.mobile_number,
        registration_id: user.registration_id,
        session_id: user.session_id,
        isRegistered: !!user.registration_id,
        messages: Array.isArray(chatHistory) ? chatHistory : [chatHistory]
      };

      setSelectedUser(chatData);
    } catch (error) {
      console.error('❌ Error in handleUserClick:', error);
      setError('Failed to load chat history. Please try again.');
    }
  };

  const handleUserUpdate = useCallback((updatedUser) => {
    setUsers(prevUsers => {
      return prevUsers.map(user => {
        if (user.session_id === updatedUser.session_id || 
            user.registration_id === updatedUser.registration_id) {
          return { ...user, ...updatedUser };
        }
        return user;
      });
    });
  }, []);

  const filteredUsers = users.filter((user) =>
    (user.chat_session_name || user.name || "Anonymous")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  return (
    <div className="relative h-full">
      {/* User List Section */}
      <div className={`w-full bg-white p-4 h-full transition-all duration-300 ${
        selectedUser ? 'md:block hidden' : 'block'
      }`}>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search users..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-3 text-sm border rounded-lg outline-none focus:ring-2 focus:ring-blue-400 bg-gray-50"
          />
        </div>

        {error && (
          <div className="text-red-500 text-center p-4 mb-4 bg-red-50 rounded-lg">
            {error}
          </div>
        )}

        <div className="h-[calc(100vh-180px)] overflow-y-auto space-y-2">
          {filteredUsers.length > 0 ? (
            filteredUsers.map((user, index) => (
              <div
                key={user.session_id || user.registration_id}
                onClick={() => handleUserClick(user)}
                className={`flex items-center p-3 hover:bg-gray-50 rounded-lg cursor-pointer transition-colors border mb-2 ${
                  selectedUser?.id === (user.registration_id || user.session_id) 
                    ? 'bg-blue-50 border-blue-200' 
                    : ''
                }`}
              >
                <div className="w-10 h-10 flex items-center justify-center text-white font-bold rounded-full bg-blue-500">
                  {(user.chat_session_name || user.name || "A")?.charAt(0)?.toUpperCase()}
                </div>
                <div className="ml-3 flex-1">
                  <div className="flex justify-between items-start">
                    <p className="font-medium">
                      {user.chat_session_name || user.name || "Anonymous"}
                    </p>
                    <span className="text-xs text-gray-500">
                      {user.last_message_time 
                        ? new Date(user.last_message_time).toLocaleTimeString([], { 
                            hour: '2-digit', 
                            minute: '2-digit' 
                          })
                        : ""}
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <p className="text-sm text-gray-500">
                      {user.chat_session_mobile_number || user.mobile_number || "No Number"}
                    </p>
                    {user.unread_count > 0 && (
                      <span className="bg-blue-500 text-white text-xs px-2 py-1 rounded-full">
                        {user.unread_count}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center py-8">
              <div className="text-gray-400 mb-2">
                <svg className="w-12 h-12 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                </svg>
              </div>
              <p className="text-gray-500">
                {isLoading ? "Loading users..." : "No users found"}
              </p>
            </div>
          )}
        </div>
      </div>

      {/* ChatWindow Section - Positioned absolutely to overlay when selected */}
     {/* ChatWindow Section - Positioned absolutely to overlay when selected */}
{selectedUser && (
  <div className="absolute inset-0 w-full h-full bg-white z-10">
    <div className="flex items-center p-4 border-b">
      <button 
        onClick={() => setSelectedUser(null)}
        className="mr-4 text-gray-600 hover:text-gray-900 transition-colors"
        aria-label="Back to user list"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M19 12H5M12 19l-7-7 7-7"/>
        </svg>
      </button>
      <h2 className="font-medium">{selectedUser.name || "Chat"}</h2>
    </div>
    <ChatWindow 
      user={selectedUser} 
      onBack={() => setSelectedUser(null)}
      onUserUpdate={handleUserUpdate}
    />
  </div>
)}
    </div>
  );
};

export default UserListSection;