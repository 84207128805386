import React, { useState, useEffect, useMemo } from 'react';
import { FaSort } from 'react-icons/fa';
import { HiPencilAlt, HiTrash } from 'react-icons/hi';
import { IoArrowDownOutline, IoArrowUpOutline } from 'react-icons/io5';
import { useNavigate, useLocation } from 'react-router-dom';
import HeaderSection from '../../../dashboard/sidebarpages/headerSection/HeaderSection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Base_URL } from '../../../apiConfig';
import { useSelector } from 'react-redux';

const AllSubCareerForm = () => {
  const [subCareers, setSubCareers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.token);

  const navigate = useNavigate();
  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};

  useEffect(() => {
    const fetchSubCareers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Base_URL}/stream-course`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setSubCareers(response.data.reverse());
      } catch (error) {
        console.error('Error fetching sub careers:', error.response ? error.response.data : error.message);
        toast.error('Failed to fetch sub careers');
      } finally {
        setLoading(false);
      }
    };

    fetchSubCareers();
  }, [token]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? (
        <IoArrowUpOutline />
      ) : (
        <IoArrowDownOutline />
      );
    }
    return <FaSort color="lightgrey" />;
  };

  const handleEditClick = (subCareer) => {
    const formattedTitle = subCareer.title.trim().replace(/\s+/g, "-");
    navigate(`/admin/career/EditSubCareerForm/${formattedTitle}`, { 
      state: {
        title: subCareer.title,
        stream_name:subCareer.stream_name,
        stream_id: subCareer.stream_id,
        created_by: subCareer.created_by
      }
    });
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this sub career?');
    if (!confirmDelete) return;

    try {
      await axios.delete(`${Base_URL}/streamcourse/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setSubCareers(prevSubCareers => prevSubCareers.filter(subCareer => subCareer.id !== id));
      toast.success('Sub Career deleted successfully.');
    } catch (error) {
      console.error('Error deleting sub career:', error.response ? error.response.data : error.message);
      toast.error('Failed to delete sub career.');
    }
  };

  const sortedSubCareers = useMemo(() => {
    let sortableItems = [...subCareers];
    if (sortConfig.key) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [subCareers, sortConfig]);

  const filteredSubCareers = sortedSubCareers.filter((subCareer) =>
    subCareer.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredSubCareers.length / entriesPerPage);
  const displayedSubCareers = filteredSubCareers.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <HeaderSection title="All Stream Courses" breadcrumb={['Stream-Course', 'All Stream Course']} />

      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
          <div className="font-poppins text-[18.5px] font-semibold text-[#312a2a]">
            All Sub Careers
          </div>
          <button
            className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen"
            onClick={() => navigate('/admin/career/subCareerForm', { state: { subTitle: 'All Stream Courses', mainTitle: 'Stream Courses' } })}
          >
            + Add New
          </button>
        </div>

        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center">
            <span className="text-sm text-gray-600 mr-2">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className="border border-gray-300 rounded"
            >
              {[5, 10, 20, 50].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center">
            <span className="text-sm text-gray-600 mr-2">Search:</span>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search by title..."
              className="px-3 py-1 border border-gray-300 rounded"
            />
          </div>
        </div>

        <div className="grid grid-cols-5 gap-2 font-semibold text-gray-600 bg-gray-100 p-3 rounded-md">
          <div onClick={() => handleSort('id')} className="cursor-pointer flex items-center">
            <span>ID</span>
            {getSortIcon('id')}
          </div>
          <div onClick={() => handleSort('title')} className="cursor-pointer flex items-center">
            <span>Title</span>
            {getSortIcon('title')}
          </div>
          <div onClick={() => handleSort('stream_id')} className="cursor-pointer flex items-center">
            <span>Stream ID</span>
            {getSortIcon('stream_id')}
          </div>
          <div onClick={() => handleSort('created_by')} className="cursor-pointer flex items-center">
            <span>Created By</span>
            {getSortIcon('created_by')}
          </div>
          <div>Actions</div>
        </div>

        {loading ? (
          <div className="text-center py-4">Loading...</div>
        ) : displayedSubCareers.length > 0 ? (
          displayedSubCareers.map((subCareer) => (
            <div key={subCareer.id} className="grid grid-cols-5 gap-2 text-tableTextColor text-sm border-b border-gray-200 p-3 items-center hover:bg-gray-100">
              <div>{subCareer.id}</div>
              <div>{subCareer.title}</div>
              <div>{subCareer.stream_id}</div>
              <div>{subCareer.created_by}</div>
              <div className="flex space-x-2">
                <button
                  onClick={() => handleEditClick(subCareer)}
                  className="bg-cmsgreen hover:bg-hovergreen text-white p-2 rounded"
                >
                  <HiPencilAlt />
                </button>
                <button
                  onClick={() => handleDelete(subCareer.id)}
                  className="bg-red-500 hover:bg-red-700 text-white p-2 rounded"
                >
                  <HiTrash />
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-4">No sub careers found</div>
        )}

        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center space-x-2">
            <button
              onClick={handlePrevious}
              className={`px-4 py-2 rounded text-sm ${
                currentPage === 1 ? 'bg-hovergreen text-white' : 'bg-cmsgreen text-white'
              }`}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className={`px-4 py-2 rounded text-sm ${
                currentPage === totalPages ? 'bg-hovergreen text-white' : 'bg-cmsgreen text-white'
              }`}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AllSubCareerForm; 