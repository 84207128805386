import React, { useEffect, useState } from "react";
import SimpleEnquiryForm from "../../../Forms/SimpleEnquiryForm";
import ExpertHelp from "../../../Forms/ExpertHelp";

const formatFees = (fee) => {
  if (fee === null || fee === undefined || isNaN(Number(fee))) {
    return 'N/A';
  }
  const feeNumber = Number(fee);
  if (feeNumber < 1) {
    return `${(feeNumber * 1000).toFixed(1)}K`;
  } else {
    return `${feeNumber.toFixed(1)}L`;
  }
};

const CourseCard = ({
  program_master_name,
  max_fees,
  min_fees,
  program_duration,
  study_mode,
  eligibility,
  course_offered,
  id,
  onViewMore,
  university_id,
  program_master_id,
  course_master_id,
  exams_accepted,
  course_count,
  course_details,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCollege, setSelectedCollege] = useState("");
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState("View More");

  useEffect(() => {
    course_count === 0 || course_count === undefined ?
      setTitle('Expert Help') : setTitle('View More');
  }, [course_count]);

  const openModal = () => {
    setSelectedCollege(program_master_name);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModal(false);
  };

  return (
    <>
      <div className="bg-white p-6 mx-auto rounded-lg border shadow-md w-full lg:w-[320px] xl:w-[400px] flex flex-col h-full">
        {/* Header */}
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-lg font-bold text-black">{program_master_name}</h3>
          <button className="flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="white"
              viewBox="0 0 24 24"
              className="w-5 h-5 stroke-gray-500"
              strokeWidth="1.5"
            >
              <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
            </svg>
          </button>
        </div>

        {/* Key Info Section */}
        <div className="flex items-center justify-between border-b pb-4 mb-4">
          <div className="flex-1 text-left">
            <p className="text-sm font-semibold text-black">
              {min_fees !== undefined && max_fees !== undefined
                ? min_fees === max_fees
                  ? formatFees(min_fees)
                  : `${formatFees(min_fees)} - ${formatFees(max_fees)}`
                : 'Not Available'}
            </p>
            <p className="text-xs text-gray-500">Fees</p>
          </div>
          <div className="flex-1 text-left">
            <p className="text-sm font-semibold text-black">{program_duration}</p>
            <p className="text-xs text-gray-500">Duration</p>
          </div>
          {!course_master_id && (
            <div className="flex-1 text-left">
              <p className="text-xs font-semibold text-black">{study_mode}</p>
              <p className="text-xs text-gray-500">Study Mode</p>
            </div>
          )}
        </div>

        {/* Eligibility Section */}
        <div className="mb-4">
          <p className="text-xs font-semibold text-black mb-1">Eligibility</p>
          <p className="text-sm text-gray-600">{eligibility || "Not Available"}</p>
        </div>

        {/* Exams Section */}
        <div className="border-t pt-4 mb-6">
          <p className="text-xs font-semibold text-black mb-1">Exam Accepted</p>
          <p className="text-sm text-gray-600">
            {exams_accepted && exams_accepted.length > 0
              ? exams_accepted.join(", ")
              : "No exams accepted"}
          </p>
        </div>

        {/* Action Buttons */}
        <div className="flex flex-col sm:flex-row gap-4 mt-auto">
          <button
            className="flex-1 px-6 py-2 text-sm font-semibold text-white bg-[#3ACA2E] rounded-[10px]"
            onClick={openModal}
          >
            Apply Now
          </button>
          <button
            className="flex-1 px-6 py-2 text-sm font-semibold text-[#3ACA2E] border border-[#3ACA2E] rounded-[10px]"
            onClick={() => (title.includes('Expert') ? setModal(true) : onViewMore(id))}
          >
            {title}
          </button>
        </div>
      </div>

      {modal && (
        <ExpertHelp
          isOpen={modal}
          onClose={closeModal}
          university_id={university_id}
          program_id={program_master_id}
          course_id={course_master_id}
        />
      )}

      {isModalOpen && (
        <SimpleEnquiryForm
          isOpen={isModalOpen}
          onClose={closeModal}
          selectedCollege={selectedCollege}
          university_id={university_id}
          program_master_id={program_master_id}
          course_master_id={course_master_id}
        />
      )}
    </>
  );
};

export default CourseCard;