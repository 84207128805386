import React from 'react';

const CareerPathVideo = ({ content }) => {
  // Function to parse and validate content
  const parseContent = (content) => {
    try {
      if (!Array.isArray(content)) {
        console.log('Content is not an array:', content);
        return [];
      }

      // Get all videos from all items
      const allVideos = content.reduce((acc, item) => {
        // Check if item has branch_sub_content_url array
        if (Array.isArray(item?.branch_sub_content_url)) {
          // Map through all videos in branch_sub_content_url
          const videos = item.branch_sub_content_url.map(subItem => ({
            video: formatEmbedUrl(subItem.video),
            title: subItem.title || 'Video'
          }));
          return [...acc, ...videos];
        }
        return acc;
      }, []);

      console.log('Processed videos:', allVideos);
      return allVideos;
    } catch (error) {
      console.error('Error parsing content:', error);
      return [];
    }
  };

  // Function to format embed URLs
  const formatEmbedUrl = (url) => {
    if (!url) return '';
  
    // Detect YouTube video URL
    const youtubeVideoRegex =
      /(?:youtu\.be\/|youtube\.com\/(?:.*v\/|.*\/videos\/|.*\/embed\/|.*\/shorts\/|.*\/watch\?v=))([^"&?\/\s]{11})/;
    const youtubePlaylistRegex = /(?:youtube\.com\/playlist\?list=)([a-zA-Z0-9_-]+)/;
  
    // Check for video ID
    const videoMatch = url.match(youtubeVideoRegex);
    if (videoMatch && videoMatch[1]) {
      const videoId = videoMatch[1];
      return `https://www.youtube.com/embed/${videoId}`;
    }
  
    // Check for playlist ID
    const playlistMatch = url.match(youtubePlaylistRegex);
    if (playlistMatch && playlistMatch[1]) {
      const playlistId = playlistMatch[1];
      return `https://www.youtube.com/embed/videoseries?list=${playlistId}`;
    }
  
    // Return other URLs as-is
    return url;
  };
  
  const videos = parseContent(content);
  console.log('Videos to render:', videos);

  if (!videos.length) {
    return (
      <div className="text-center text-gray-500 py-8">No videos available</div>
    );
  }

  return (
    <div className="w-full px-4 py-8">
      <h2 className="text-xl font-bold text-center mb-8">Curated Videos For Learning</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {videos.map((item, index) => (
          item.video && (
            <div key={index} className="flex flex-col gap-2">
              <div className="relative aspect-video w-full overflow-hidden rounded-lg shadow-lg">
                <iframe
                  src={item.video}
                  title={item.title}
                  className="absolute inset-0 w-full h-full"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  loading="lazy"
                />
              </div>
              <p className="text-center font-medium">{item.title}</p>
            </div>
          )
        ))}
      </div>
    </div>
  );
};

export default CareerPathVideo;