import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../../apiConfig";
import HeaderTitle from "../../dashboard/HeaderTitle";

const AddAdmissions = () => {
  const [formData, setFormData] = useState({
    university_id: null,
    admission_desc: "",
    document_list: []
  });

  const [universities, setUniversities] = useState([]);
  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};
  const [loading, setLoading] = useState(false); // Track loading state
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    // Fetch the list of universities from the API
    const fetchUniversities = async () => {
      try {
        const response = await axios.get(`${Base_URL}/universities`);
        const universityOptions = response.data.map((uni) => ({
          value: uni.university_id,
          label: uni.university_name,
        }));
        setUniversities(universityOptions);
      } catch (error) {
        console.error("Failed to fetch universities:", error);
        toast.error("Failed to fetch universities.");
      }
    };

    fetchUniversities();
  }, []);

  const handleSelectChange = (selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      university_id: selectedOption ? selectedOption.value : null,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!formData.university_id) {
      toast.error("Please select a university.");
      setLoading(false);
      return;
    }

    try {
      const dataToSubmit = {
        ...formData,
        document_list: formData.document_list.split(',').map(exam => exam.trim()),
      };
      await axios.post(`${Base_URL}/admission`, dataToSubmit, {
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}`
        },
        withCredentials: true
      });

      toast.success("Admission information added successfully!");
    } catch (error) {
      console.error("Failed to add admission information:", error.response?.data || error.message);
      toast.error(`Failed to add admission information.${error.response.data?.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
      <div className="bg-white p-4 rounded-md">
        <h2 className="text-xl font-semibold mb-4">Add Admission Information</h2>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Select University Dropdown using react-select */}
            <div className="flex flex-col">
              <label htmlFor="university_id" className="mb-2 font-medium text-sm">Select University</label>
              <Select
                options={universities}
                value={universities.find(option => option.value === formData.university_id)}
                onChange={handleSelectChange}
                placeholder="Select a university"
                className="basic-multi-select"
                classNamePrefix="select"
                isClearable
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="document_list" className="mb-2 font-medium text-sm">Documents Required</label>
              <textarea
                id="document_list"
                name="document_list"
                value={formData.document_list}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                rows="4"
                required
              />
            </div>
            {/* CKEditor for Admission Description */}
            <div className="flex flex-col md:col-span-2">
              <label htmlFor="admission_description" className="mb-2 font-medium text-sm">Admission Description</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.admission_desc}
                onChange={(event, editor) => setFormData((prev) => ({
                  ...prev,
                  admission_desc: editor.getData(),
                }))}
              />
            </div>
          </div>

          <button type="submit" className="mt-4 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen">
            {loading ? ( // Show spinner when loading
              <div className="flex justify-center items-center">
                <svg
                  className="animate-spin h-5 w-5 text-white mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                Submitting...
              </div>
            ) : (
              'Submit Admission'
            )}
          </button>
        </form>

        {/* Toast Container */}
        <ToastContainer />
      </div>
    </div>
  );
};

export default AddAdmissions;
