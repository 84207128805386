import React, { useState, useEffect, useMemo } from "react";
import { HiPencil, HiTrash } from "react-icons/hi";
import { IoArrowDownOutline, IoArrowUpOutline } from "react-icons/io5";
import { FaSort } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { Base_URL } from "../../../apiConfig";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from 'react-redux';


const AllMaster = () => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [selectedType, setSelectedType] = useState(
    location.state?.selectedType || "city"
  );
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(20);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [refreshKey, setRefreshKey] = useState(0);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);

  const fetchData = async (type) => {
    try {
      setData([]); // Clear existing data before fetching new data
      setError(null); // Clear any previous errors
  
      let response;
      if (type === "city_mapping") {
        response = await axios.post(`${Base_URL}/get-with-joins/city_mapping`, {
          joins: [
            {
              joinTable: "universities",
              joinCondition:
                "universities.university_id=city_mapping.university_id",
            },
            {
              joinTable: "city",
              joinCondition: "city.id=city_mapping.city_id",
            },
          ],
        });
      } else {
        response = await axios.get(`${Base_URL}/get/${type}`);
      }
  
      setData(response.data || []);
    } catch (error) {
      console.error(
        `Error fetching ${type} data:`,
        error.response ? error.response.data : error.message
      );
      setError(`Failed to fetch ${type} data.`);
    }
  };

  const handleTypeChange = (newType) => {
    setSelectedType(newType);
    setRefreshKey((prev) => prev + 1); // Increment key to force re-render
  };
  
  useEffect(() => {
    const typeToFetch =
      selectedType === "university_city"
        ? "city_mapping"
        : selectedType === "company"
        ? "company_master"
        : selectedType;
    fetchData(typeToFetch);
  }, [selectedType]);

  // const handleSort = (key) => {
  //   let direction = "ascending";
  //   if (sortConfig.key === key && sortConfig.direction === "ascending") {
  //     direction = "descending";
  //   }
  //   setSortConfig({ key, direction });
  // };

  const handleSort = (key) => {
  const adjustedKey = (() => {
    switch (selectedType) {
      case "streams":
        return key === "name" ? "stream_name" : key;
      case "sub_courses_master":
        return key === "name" ? "sub_course_name" : key;
      case "course_master":
        return key === "name" ? "course_master_name" : key;
      case "program_master":
        return key === "name" ? "program_master_name" : key;
      case "university_city":
        return key === "name" ? "university_name" : key;
      default:
        return key;
    }
  })();

  // Toggle direction if the same key is clicked
  let direction = "ascending";
  if (sortConfig.key === adjustedKey && sortConfig.direction === "ascending") {
    direction = "descending";
  }

  setSortConfig({ key: adjustedKey, direction });
};

  

  // const getSortIcon = (key) => {
  //   if (sortConfig.key === key) {
  //     return sortConfig.direction === "ascending" ? (
  //       <IoArrowUpOutline />
  //     ) : (
  //       <IoArrowDownOutline />
  //     );
  //   }
  //   return <FaSort color="lightgrey" />;
  // };

  const getSortIcon = (key) => {
    const adjustedKey = (() => {
      switch (selectedType) {
        case "streams":
          return key === "name" ? "stream_name" : key;
        case "sub_courses_master":
          return key === "name" ? "sub_course_name" : key;
        case "course_master":
          return key === "name" ? "course_master_name" : key;
        case "program_master":
          return key === "name" ? "program_master_name" : key;
        case "university_city":
          return key === "name" ? "university_name" : key;
        default:
          return key;
      }
    })();
  
    if (sortConfig.key === adjustedKey) {
      return sortConfig.direction === "ascending" ? (
        <IoArrowUpOutline />
      ) : (
        <IoArrowDownOutline />
      );
    }
    return <FaSort color="lightgrey" />;
  };
  
  

  // const sortedData = useMemo(() => {
  //   let sortableData = [...data];
  //   if (sortConfig.key) {
  //     sortableData.sort((a, b) => {
  //       if (a[sortConfig.key] < b[sortConfig.key]) {
  //         return sortConfig.direction === "ascending" ? -1 : 1;
  //       }
  //       if (a[sortConfig.key] > b[sortConfig.key]) {
  //         return sortConfig.direction === "ascending" ? 1 : -1;
  //       }
  //       return 0;
  //     });
  //   }
  //   return sortableData;
  // }, [data, sortConfig]);

  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        const valueA = a[sortConfig.key]?.toString().toLowerCase() || "";
        const valueB = b[sortConfig.key]?.toString().toLowerCase() || "";
  
        if (valueA < valueB) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);
  
  const handleEditClick = (item) => {
    let editPath;
    if (selectedType === "university_city") {
      editPath = "editUniversityCity";
    } else if (selectedType === "course_master") {
      editPath = "editUniCourses";
    } else if (selectedType === "sub_courses_master") {
      editPath = "editSubCourse";
    } else if (selectedType === "program_master") {
      editPath = "editUniPrograms";
    } else {
      editPath = `edit${capitalizeFirstLetter(selectedType)}`;
    }
    navigate(`/admin/universityMaster/${editPath}/${item.id}`, {
      state: {
        item,
        mainTitle: `Edit ${capitalizeFirstLetter(selectedType)}`,
        subTitle: `Modify ${capitalizeFirstLetter(selectedType)} details`,
      },
    });
  };

  // const handleDeleteClick = async (id) => {
  //   const confirmDelete = window.confirm(`Are you sure you want to delete this ${capitalizeFirstLetter(selectedType)}?`);
  //   if (!confirmDelete) return;
  
  //   try {
  //       // Map the selectedType to the actual table name
  //   const tableName = (() => {
  //     switch (selectedType) {
  //       case "university_city":
  //         return "city_mapping";
  //       case "company":
  //         return "company_master";
  //       default:
  //         return selectedType; // Use selectedType for other cases
  //     }
  //   })();
  //     await axios.put(
  //       `${Base_URL}/update/delete/${tableName}/${id}`,
  //       {}, // Send an empty body for the PUT request
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`, // Replace `token` with your actual token variable
  //         },
  //       }
  //     );
  //     toast.success(`${capitalizeFirstLetter(selectedType)} deleted successfully`);
      
  //     // Remove the deleted item from the current data state
  //     setData((prevData) => prevData.filter((item) => item.id !== id));
  //   } catch (err) {
  //     console.error(`Error deleting ${selectedType}:`, err.response ? err.response.data : err.message);
  //     toast.error(`Failed to delete ${capitalizeFirstLetter(selectedType)}`);
  //   }
  // };
  const handleDeleteClick = async (id) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete this ${capitalizeFirstLetter(selectedType)}?`
    );
    if (!confirmDelete) return;
  
    try {
      // Map selectedType to the correct table name and API endpoint
      const { tableName, endpoint } = (() => {
        switch (selectedType) {
          case "university_city":
            return { tableName: "city_mapping", endpoint: `/update/delete/city_mapping/${id}` };
          case "company":
            return { tableName: "company_master", endpoint: `/update/delete/company_master/${id}` };
          case "streams":
            return { tableName: "streams", endpoint: `/stream/${id}` };
          case "program_master":
            return { tableName: "program_master", endpoint: `/program-master/${id}` };
          case "course_master":
            return { tableName: "course_master", endpoint: `/course-master/${id}` };
          default:
            return { tableName: selectedType, endpoint: `/update/delete/${selectedType}/${id}` };
        }
      })();
  
      // Use axios.delete for specific cases, otherwise axios.put
      if (["streams", "program_master", "course_master"].includes(selectedType)) {
        await axios.delete(`${Base_URL}${endpoint}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        await axios.put(
          `${Base_URL}${endpoint}`,
          {}, // Send an empty body for the PUT request
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
  
      toast.success(`${capitalizeFirstLetter(selectedType)} deleted successfully`);
  
      // Remove the deleted item from the current data state
      setData((prevData) => prevData.filter((item) => item.id !== id));
    } catch (err) {
      console.error(`Error deleting ${selectedType}:`, err.response );
      toast.error(err.response.data.message);
    }
  };
  
  
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Pagination logic
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedData.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(sortedData.length / entriesPerPage);
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className='p-6 bg-gray-100 min-h-screen' key={refreshKey}>
      <HeaderTitle
        mainTitle='All Master Data'
        subTitle={`Manage ${
          selectedType === "university_city"
            ? "University City"
            : selectedType === "company"
            ? "Company"
            : selectedType === "course_master"
            ? "Courses"
            : selectedType === "program_master"
            ? "Programs"
            : capitalizeFirstLetter(selectedType)
        } Data`}
      />

      <div className='bg-white p-6 rounded-lg shadow'>
        <div className='flex items-center justify-between mb-4 pb-2 border-b border-gray-200'>
          <div
            className='font-poppins'
            style={{
              color: "rgb(49, 42, 42)",
              fontSize: "18.5px",
              fontWeight: 600,
              lineHeight: "26.25px",
              height: "45px",
            }}
          >
            All{" "}
            {selectedType === "university_city"
              ? "University City"
              : selectedType === "company"
              ? "Company"
              : selectedType === "course_master"
              ? "Courses"
              : selectedType === "program_master"
              ? "Programs"
              : capitalizeFirstLetter(selectedType)}
          </div>
          <div className='flex items-center space-x-4'>
          <select
  value={selectedType}
  onChange={(e) => handleTypeChange(e.target.value)} // Use handleTypeChange
  className="border border-gray-300 rounded"
>
  <option value="city">City</option>
  <option value="company">Company</option>
  <option value="country">Country</option>
  <option value="state">State</option>
  <option value="streams">Streams</option>
  <option value="sub_courses_master">Sub Courses</option>
  {/* <option value="university_city">University City</option> */}
  <option value="program_master">Programs</option>
  <option value="course_master">Courses</option>
</select>

          </div>
        </div>

        <div className='overflow-x-auto'>
          <table className='min-w-full bg-white'>
            <thead>
              <tr className='bg-white'>
                {selectedType === "university_city"
                  ? ["ID", "University Name", "City Name","Created On", "Created By", "Actions"].map(
                      (heading) => (
                        <th
                          key={heading}
                          className='text-left border-b'
                          style={{
                            color: "rgb(49, 42, 42)",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "14px",
                            fontWeight: 600,
                            lineHeight: "21px",
                            padding: "14px 13px",
                            borderBottomColor: "rgb(230, 230, 230)",
                          }}
                          onClick={() => handleSort(heading.toLowerCase().replace(" ", "_"))}
                        >
                        <div className="flex items-center space-x-1">
                          <span>{heading}</span>
                          {getSortIcon(heading.toLowerCase().replace(" ", "_"))}
                        </div>
                        </th>
                      )
                    )
                  : ["ID", "Name","Created On", "Created By", "Actions"].map((heading) => (
                      <th
                        key={heading}
                        className='text-left border-b'
                        style={{
                          color: "rgb(49, 42, 42)",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          fontWeight: 600,
                          lineHeight: "21px",
                          padding: "14px 13px",
                          borderBottomColor: "rgb(230, 230, 230)", 
                        }}
                        onClick={() => handleSort(heading.toLowerCase().replace(" ", "_"))}
                      >
                       <div className="flex items-center space-x-1">
                          <span>{heading}</span>
                          {getSortIcon(heading.toLowerCase().replace(" ", "_"))}
                        </div>
                      </th>
                    ))}
              </tr>
            </thead>
            <tbody>
              {currentEntries.length > 0 ? (
                currentEntries.map((item) => (
                  <tr
                    key={item.id}
                    className='hover:bg-white hover:shadow-md border-b'
                    style={{
                      borderTopWidth: "1px",
                      borderTopColor: "rgb(230, 230, 230)",
                      verticalAlign: "top",
                    }}
                  >
                    <td
                      className='text-left border-t border-b'
                      style={{
                        color: "rgb(115, 123, 139)",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13.125px",
                        fontWeight: 400,
                        lineHeight: "19.6px",
                        padding: "9.1px 13.125px",
                        borderBottomColor: "rgb(230, 230, 230)",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "150px",
                      }}
                    >
                      {item.id}
                    </td>
                    {selectedType === "university_city" ? (
                      <>
                        <td
                          className='text-left border-t border-b'
                          style={{
                            color: "rgb(115, 123, 139)",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "13.125px",
                            fontWeight: 400,
                            lineHeight: "19.6px",
                            padding: "9.1px 13.125px",
                            borderBottomColor: "rgb(230, 230, 230)",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px",
                          }}
                        >
                          {item.university_name}
                        </td>
                        <td
                          className='text-left border-t border-b'
                          style={{
                            color: "rgb(115, 123, 139)",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "13.125px",
                            fontWeight: 400,
                            lineHeight: "19.6px",
                            padding: "9.1px 13.125px",
                            borderBottomColor: "rgb(230, 230, 230)",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px",
                          }}
                        >
                          {item.name}
                        </td>
                      </>
                    ) : (
                      <td
                        className='text-left border-t border-b'
                        style={{
                          color: "rgb(115, 123, 139)",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "13.125px",
                          fontWeight: 400,
                          lineHeight: "19.6px",
                          padding: "9.1px 13.125px",
                          borderBottomColor: "rgb(230, 230, 230)",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px",
                        }}
                      >
                        {item.name ||
                          item.university_name ||
                          item.program_master_name ||
                          item.course_master_name ||
                          item.stream_name ||
                          item.sub_course_name}
                      </td>
                    )}
  <td
          className="text-left border-t border-b"
          style={{
            color: "rgb(115, 123, 139)",
            fontFamily: "Poppins, sans-serif",
            fontSize: "13.125px",
            fontWeight: 400,
            lineHeight: "19.6px",
            padding: "9.1px 13.125px",
            borderBottomColor: "rgb(230, 230, 230)",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "150px",
          }}
        >
          {item.created_on ?item.created_on.split('T')[0] : 'N/A'}
        </td>
        <td
          className="text-left border-t border-b"
          style={{
            color: "rgb(115, 123, 139)",
            fontFamily: "Poppins, sans-serif",
            fontSize: "13.125px",
            fontWeight: 400,
            lineHeight: "19.6px",
            padding: "9.1px 13.125px",
            borderBottomColor: "rgb(230, 230, 230)",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "150px",
          }}
        >
          {item.created_by || "N/A"}
        </td>
       
                    
                    <td
                      className='text-left border-t border-b'
                      style={{
                        color: "rgb(115, 123, 139)",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13.125px",
                        fontWeight: 400,
                        lineHeight: "19.6px",
                        padding: "9.1px 13.125px",
                        borderBottomColor: "rgb(230, 230, 230)",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "150px",
                      }}
                    >
                      <div className='flex space-x-2'>
                        <button
                          onClick={() => handleEditClick(item)}
                          className='p-2 rounded bg-cmsgreen text-white hover:bg-hovergreen'
                        >
                          <HiPencil />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(item.id)}
                          className='p-2 rounded bg-red-500 text-white hover:bg-red-700'
                        >
                          <HiTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='4' className='text-center py-4 text-gray-600'>
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className='flex justify-between items-center mt-4'>
          <div className='flex items-center'>
            <span className='text-sm text-gray-600 mr-2'>Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => {
                setEntriesPerPage(Number(e.target.value));
                setCurrentPage(1); // Reset to first page when entries per page changes
              }}
              className='border border-gray-300 rounded'
            >
              {[10, 20, 50, 100].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className='flex items-center space-x-2'>
            <button
              onClick={handlePrevious}
              className="px-4 py-2 bg-cmsgreen text-white rounded-md disabled:bg-hovergreen text-sm"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className='text-sm text-gray-600'>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className="px-4 py-2 bg-cmsgreen text-white rounded-md disabled:bg-hovergreen text-sm"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};

export default AllMaster;
