import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { FaSort } from 'react-icons/fa';
import { HiPencilAlt, HiTrash } from 'react-icons/hi';
import { IoArrowDownOutline, IoArrowUpOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Base_URL } from '../../../apiConfig';
import HeaderTitle from '../../dashboard/HeaderTitle';


const AllExam = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};
  const [exams, setExams] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(20);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' }); // Sorting state


  const token = useSelector((state) => state.auth.token); // Get token from Redux

  useEffect(() => {
    const fetchExams = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Base_URL}/exam-master`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add token in headers
          },
        });
        setExams(response.data);
      } catch (err) {
        toast.error('Failed to fetch exams');
      } finally {
        setLoading(false);
      }
    };
    fetchExams();
  }, [token]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? (
        <IoArrowUpOutline />
      ) : (
        <IoArrowDownOutline />
      );
    }
    return <FaSort color="lightgrey" />;
  };

  const sortedExams = useMemo(() => {
    let sortableExams = [...exams];
    if (sortConfig.key) {
      sortableExams.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableExams;
  }, [exams, sortConfig]);

  const filteredExams = sortedExams.filter((exam) =>
    exam?.exam_master_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const totalEntries = filteredExams.length;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const displayedExams = filteredExams.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  const handlePrevious = () => setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  const handleNext = () => setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this exam?');
    if (!confirmDelete) return;

    try {
      await axios.put(
        `${Base_URL}/update/delete/exam_master/${id}`,
        {}, // Empty body
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add token in headers
          },
        }
      );
      toast.success('Exam deleted successfully');
      setExams((prev) => prev.filter((exam) => exam.id !== id));
    } catch (err) {
      toast.error('Failed to delete exam');
    }
  };

  return (
    <div>
      <div className="bg-gray-100 p-6 min-h-screen overflow-auto">
        <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
        <div className="bg-white p-4 rounded-md shadow-md">
          <div className="flex justify-between items-center mb-4 pb-2 border-b border-gray-200">
            <h2 className="text-xl font-semibold mb-4">{subTitle}</h2>
            <button
              className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen"
              onClick={() => navigate('/admin/exams/addExams', { state: { mainTitle, subTitle: 'Add Exam Level' } })}
            >
              + Add New
            </button>
          </div>

          {/* <div className="flex justify-end mb-4">
            <input
              type="text"
              className="px-3 py-1 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search by Exam Name"
            />
          </div> */}
      <div className="flex justify-between items-center mb-4">
  {/* Total Count */}
  <div className="text-sm font-semibold text-tableTextColor">
    Total Exams: <span className="font-semibold">{exams.filter((exam) =>
      exam?.exam_master_name.toLowerCase().includes(searchTerm.toLowerCase())
    ).length}</span>
  </div>

  {/* Search Input */}
  <div className="flex items-center text-gray-600">
    <span className="mr-2 text-sm">Search:</span>
    <input
      type="text"
      className="px-3 py-1 border border-gray-300 rounded"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      placeholder="Search by Exam Name"
    />
  </div>
</div>


          {/* Table Headers with Sorting */}
          <div className="grid grid-cols-5 gap-2 font-semibold text-gray-600 bg-gray-100 p-3 rounded-md">
            <div onClick={() => handleSort('id')}>
              <div className="flex items-center space-x-1">
                <span>S.No</span>
                {getSortIcon('id')}
              </div>
            </div>
            <div onClick={() => handleSort('exam_master_name')}>
              <div className="flex items-center space-x-1">
                <span>Exam Name</span>
                {getSortIcon('exam_master_name')}
              </div>
            </div>
            <div onClick={() => handleSort('created_on')}>
          <div className='flex items-center space-x-1'>
              <span> Created On</span>
              {getSortIcon('created_on')}
            </div>
              
            </div>
            <div onClick={() => handleSort('created_by')} >
            <div className='flex items-center space-x-1'>
              <span> Created By</span>
              {getSortIcon('created_by')}
            </div>
            </div>
            <div>Action</div>
          </div>

          {/* Table Body */}
          {displayedExams.length > 0 ? (
            displayedExams.map((exam, index) => (
              <div
                key={index}
                className="grid grid-cols-5 text-tableTextColor gap-2 text-sm border-b border-gray-200 p-3 items-center hover:bg-gray-100"
              >
                <div>{index + 1}</div>
                <div className="truncate">{exam.exam_master_name}</div>
                <div>{exam.created_on ?exam.created_on.split('T')[0] : 'N/A'}</div>
              <div>{exam.created_by}</div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => navigate(`/admin/exams/editExam/${exam.exam_master_id}`, { state: exam })}
                    className="bg-cmsgreen hover:bg-hovergreen text-white p-2 rounded"
                  >
                    <HiPencilAlt />
                  </button>
                  <button
                    className="bg-red-500 hover:bg-red-700 text-white p-2 rounded"
                    onClick={() => handleDelete(exam.id)}
                  >
                    <HiTrash />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center py-4">No Exams found</div>
          )}

          {/* Pagination */}
          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center">
              <span className="text-sm text-gray-600 mr-2">Show:</span>
              <select
                value={entriesPerPage}
                onChange={(e) => {
                  setEntriesPerPage(Number(e.target.value));
                  setCurrentPage(1);
                }}
                className="border border-gray-300 rounded"
              >
                {[5, 10, 20].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={handlePrevious}
                className="px-4 py-2 bg-cmsgreen text-white rounded-md disabled:bg-hovergreen text-sm"
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span className="text-sm text-gray-600">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={handleNext}
                className="px-4 py-2 bg-cmsgreen text-white rounded-md disabled:bg-hovergreen text-sm"
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AllExam;
