import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StudentDashboardSidebar from './StudentDashboardSidebar/StudentDashboardSidebar';
import StudentProfile from './StudentDashboardSidebar/StudentProfile';
import StudentDashboard from './StudentDashboard';
import WishlistedColleges from './StudentDashboardSidebar/WishlistedColleges';
import UploadDocument from './StudentDashboardSidebar/UploadDocuments';
import CareersGrid from "../pages/CareersGrid";
import CareerBranchSubContent from '../pages/CareerBranchSubContent';
import axios from 'axios';
import { Base_URL } from '../apiConfig';
import { useSelector } from 'react-redux';

const DashboardLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeComponent, setActiveComponent] = useState('Dashboard');
  const [showCareersGrid, setShowCareersGrid] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const [careerPreferences, setCareerPreferences] = useState([]);
  const [careerData, setCareerData] = useState([]);
  
  // Redirect to dashboard if on root path
  useEffect(() => {
    if (location.pathname === '/student-dashboard/') {
      navigate('/student-dashboard');
    }
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location.pathname, activeComponent, navigate]);

  useEffect(() => {
    // Check if we're returning from adding a preference
    if (location.state?.showCareer) {
      setActiveComponent('Career');
      setShowCareersGrid(false);
      // Clear the state
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state, navigate]);

  useEffect(() => {
    if (activeComponent === 'Career') {
      fetchCareerPreferences();
      fetchCareerData();
    }
  }, [activeComponent, showCareersGrid]);

  const fetchCareerPreferences = async () => {
    try {
      const response = await axios.get(`${Base_URL}/student/career/career-preference`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCareerPreferences(response.data);
    } catch (error) {
      console.error('Error fetching career preferences:', error);
    }
  };

  const fetchCareerData = async () => {
    try {
      const response = await axios.get(`${Base_URL}/student/career`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCareerData(response.data);
    } catch (error) {
      console.error('Error fetching career data:', error);
    }
  };

  const handlePreferenceClick = (preference) => {
    // For preferences from the right sidebar
    if (preference.branch_sub_content_title) {
      const formattedTitle = preference.branch_sub_content_title.trim().replace(/\s+/g, "-").toLowerCase();
      navigate(`/career/${formattedTitle}/details`);
    }
  };

  const handleCareerClick = (career) => {
    // For career options from the grid
    if (career.title) {
      const formattedTitle = career.title.trim().replace(/\s+/g, "-").toLowerCase();
      navigate(`/career/${formattedTitle}/details`);
    }
  };
  
  // Render content based on active component
  const renderContent = () => {
    switch (activeComponent) {
      case 'Dashboard':
        return <StudentDashboard onCompleteProfile={() => setActiveComponent('My Profile')} />;
      case 'My Profile':
        return <StudentProfile />;
      case 'College Admission':
        return <WishlistedColleges />;
      case 'Counsellors':
        return <div className="p-4">Counsellors content goes here</div>;
      case 'Payment History':
        return <div className="p-4">Payment History content goes here</div>;
      case 'Documents':
        return <UploadDocument />;
      case 'Career':
        return (
          <div className="p-4">
            {!showCareersGrid && (
              <div className="grid grid-cols-2 gap-4 mb-8">
                <div className="border-r border-gray-300 pr-4">
                  <div className="flex items-center h-full">
                    <h2 className="text-xl md:text-2xl font-bold">Explore Career Options</h2>
                  </div>
                </div>
                <div className="pl-4 flex justify-end items-center">
                  <button 
                    onClick={() => setShowCareersGrid(true)}
                    className="bg-[#3ACA2E] text-white px-6 py-2 rounded-lg hover:bg-[#2fb926] whitespace-nowrap font-medium"
                  >
                    Add Preference
                  </button>
                </div>
              </div>
            )}
            {showCareersGrid ? (
              <CareersGrid />
            ) : (
              <div className="flex flex-col lg:flex-row gap-6">
                <div className="w-full lg:w-3/4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {careerData.map((career) => (
                      <div
                        key={career.id}
                        className="border-[#75E36B] bg-white text-black shadow-md rounded-[10px] h-[60px] relative flex flex-col hover:bg-[#D9D9D9] hover:border-[#75E36B] hover:text-black transition-all duration-300"
                      >
                        <button
                          className="w-full h-[60px] bg-transparent text-black font-bold rounded-[10px] border border-[#75E36B] hover:bg-[#75E36B] hover:text-white transition-all ease-out duration-300 text-sm text-center break-words px-2"
                          onClick={() => handleCareerClick(career)}
                        >
                          {career.title}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="w-full lg:w-1/4 bg-gray-50 p-4 rounded-lg border border-gray-300">
                  <h3 className="text-lg font-semibold mb-4">Your Preferences</h3>
                  <div className="flex flex-col gap-3 max-h-[500px] overflow-y-auto pr-2 custom-scrollbar">
                    {careerPreferences.map((pref) => (
                      <button
                        key={pref.id}
                        onClick={() => handlePreferenceClick(pref)}
                        className="p-3 bg-white rounded-lg shadow hover:shadow-md transition-all duration-300 text-left w-full min-h-[60px] flex flex-col justify-center"
                      >
                        <p className="font-medium text-black line-clamp-1">{pref.value}</p>
                        <p className="text-sm text-black mt-1 line-clamp-1">{pref.branch_sub_content_title}</p>
                      </button>
                    ))}
                    {careerPreferences.length === 0 && (
                      <p className="text-gray-500 text-sm text-center">No preferences added yet</p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      case 'Account Settings':
        return <div className="p-4">Account Settings content goes here</div>;
      default:
        return <StudentDashboard onCompleteProfile={() => setActiveComponent('My Profile')} />;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="min-h-screen pt-[64px]">
        <StudentDashboardSidebar 
          activeComponent={activeComponent} 
          setActiveComponent={setActiveComponent} 
        />
        <div className="flex-grow">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-[1200px] pt-4">
            <div className="w-full max-w-full overflow-x-hidden">
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Add this CSS to your existing styles or create a new style block
const styles = `
  .custom-scrollbar::-webkit-scrollbar {
    width: 6px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export default DashboardLayout;
