import apiClient from '../apiClient'; // Import the Axios instance with interceptors
import { API_ENDPOINT } from '../../utils/constant';

const MasterDataService = {
  /**
   * Fetch specializations (sub-courses).
   * @returns {Promise<Array>} List of sub-courses.
   */
  fetchSpecializations: async () => {
    try {
      const response = await apiClient.get(API_ENDPOINT.SPECIALIZATION);
      return response.data;
    } catch (error) {
      console.error("Error fetching specializations:", error.message);
      throw new Error(
        error.response?.data?.message || "Unable to fetch specializations"
      );
    }
  },

 /**
   * Fetch streams using `/streams/all`.
   * @returns {Promise<Array>} List of streams.
   */
 fetchDegreeByStreams: async () => {
  try {
    const response = await apiClient.get(`${API_ENDPOINT.STREAMS_ALL}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching streams:", error.message);
    throw new Error(
      error.response?.data?.message || "Unable to fetch streams"
    );
  }
},

  /**
   * Fetch cities.
   * @returns {Promise<Array>} List of cities.
   */
  fetchCities: async () => {
    try {
      const response = await apiClient.get(API_ENDPOINT.CITY);
      return response.data;
    } catch (error) {
      console.error("Error fetching cities:", error.message);
      throw new Error(
        error.response?.data?.message || "Unable to fetch cities"
      );
    }
  },

  /**
   * Fetch states.
   * @returns {Promise<Array>} List of states.
   */
  fetchStates: async () => {
    try {
      const response = await apiClient.get(API_ENDPOINT.STATE);
      return response.data;
    } catch (error) {
      console.error("Error fetching states:", error.message);
      throw new Error(
        error.response?.data?.message || "Unable to fetch states"
      );
    }
  },

  /**
   * Fetch degrees (program master).
   * @returns {Promise<Array>} List of degrees.
   */
  fetchDegrees: async () => {
    try {
      const response = await apiClient.get(API_ENDPOINT.PROGRAM);
      return response.data;
    } catch (error) {
      console.error("Error fetching degrees:", error.message);
      throw new Error(
        error.response?.data?.message || "Unable to fetch degrees"
      );
    }
  },

  /**
   * Fetch modes.
   * @returns {Promise<Array>} List of modes.
   */
  fetchModes: async () => {
    try {
      const response = await apiClient.get(`${API_ENDPOINT.MODE_MASTER}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching modes:", error.message);
      throw new Error(
        error.response?.data?.message || "Unable to fetch modes"
      );
    }
  },

  /**
   * Fetch levels.
   * @returns {Promise<Array>} List of levels.
   */
  fetchLevels: async () => {
    try {
      const response = await apiClient.get(`${API_ENDPOINT.LEVEL_MASTER}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching levels:", error.message);
      throw new Error(
        error.response?.data?.message || "Unable to fetch levels"
      );
    }
  },

  /**
   * Fetch streams.
   * @returns {Promise<Array>} List of streams.
   */
  fetchStreams: async () => {
    try {
      const response = await apiClient.get(`${API_ENDPOINT.STREAMS}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching streams:", error.message);
      throw new Error(
        error.response?.data?.message || "Unable to fetch streams"
      );
    }
  },
 /**
   * Fetch durations.
   * @returns {Promise<Array>} List of durations.
   */
 fetchDurations: async () => {
  try {
    const response = await apiClient.get(API_ENDPOINT.DURATION_MASTER);
    return response.data;
  } catch (error) {
    console.error("Error fetching durations:", error.message);
    throw new Error(
      error.response?.data?.message || "Unable to fetch durations"
    );
  }
},


};

export default MasterDataService;
