import React, { useState, useEffect, useMemo } from "react";
import { HiPencilAlt, HiTrash } from "react-icons/hi";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../../apiConfig";
import { htmlToText } from "html-to-text";
import { IoArrowDownOutline, IoArrowUpOutline } from "react-icons/io5";
import { FaSort } from "react-icons/fa";
import { useSelector } from "react-redux"; // For token if needed

const AllCourses = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(20);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  const token = useSelector((state) => state.auth.token); // Get token from Redux

  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Base_URL}/courses`);
        setCourses(response.data);
      } catch (err) {
        toast.error("Failed to fetch Courses");
      } finally {
        setLoading(false);
      }
    };
    fetchCourses();
  }, []);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? (
        <IoArrowUpOutline />
      ) : (
        <IoArrowDownOutline />
      );
    }
    return <FaSort color="lightgrey" />;
  };

  const sortedCourses = useMemo(() => {
    let sortableCourses = [...courses];
    if (sortConfig.key) {
      sortableCourses.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableCourses;
  }, [courses, sortConfig]);

  const filteredEntries = sortedCourses
    .filter((course) =>
      course.course_master_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice((currentPage - 1) * entriesPerPage, currentPage * entriesPerPage);

  const totalPages = Math.ceil(
    courses.filter((course) =>
      course.course_master_name.toLowerCase().includes(searchTerm.toLowerCase())
    ).length / entriesPerPage
  );

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this course?"
    );
    if (!confirmDelete) return;

    try {
      await axios.delete(
        `${Base_URL}/courses/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`, // Include token if needed
          },
        }
      );
      toast.success("Course deleted successfully");
      setCourses((prev) => prev.filter((course) => course.id !== id)); // Update state
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <div className="bg-gray-100 p-6 min-h-screen overflow-auto">
      <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
      <div className="bg-white p-4 rounded-md shadow-md">
        <div className="flex justify-between items-center mb-4 pb-2 border-b border-gray-200">
          <h2 className="text-xl font-semibold mb-4">All Courses</h2>
          <button
            className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen"
            onClick={() =>
              navigate("/admin/courses/add", {
                state: { mainTitle, subTitle: "Add Courses" },
              })
            }
          >
            + Add New
          </button>
        </div>
        {/* <div className="flex items-center justify-end space-x-4 mb-4">
          <div className="flex items-center text-gray-600">
            <span className="mr-2 text-sm">Search:</span>
            <input
              type="text"
              className="px-3 py-1 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search by Course Name"
            />
          </div>
        </div> */}
        <div className="flex justify-between items-center mb-4">
  {/* Total Count */}
  <div className="text-sm font-semibold text-tableTextColor">
    Total Courses: <span className="font-semibold">{courses.filter((course) =>
      course.course_master_name.toLowerCase().includes(searchTerm.toLowerCase())
    ).length}</span>
  </div>

  {/* Search Input */}
  <div className="flex items-center text-gray-600">
    <span className="mr-2 text-sm">Search:</span>
    <input
      type="text"
      className="px-3 py-1 border border-gray-300 rounded"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      placeholder="Search by Course Name"
    />
  </div>
</div>


        {/* Table Headers with Sorting */}
        <div className="grid grid-cols-8 gap-2 font-semibold text-gray-600 bg-gray-100 p-3 rounded-md">
          <div onClick={() => handleSort("id")}>
            <div className="flex items-center space-x-1">
              <span>S.No</span>
              {getSortIcon("id")}
            </div>
          </div>
          <div onClick={() => handleSort("course_master_name")}>
            <div className="flex items-center space-x-1">
              <span>Course Name</span>
              {getSortIcon("course_master_name")}
            </div>
          </div>
          <div onClick={() => handleSort("program_master_name")}>
            <div className="flex items-center space-x-1">
              <span>Program Name</span>
              {getSortIcon("program_master_name")}
            </div>
          </div>
          <div onClick={() => handleSort("university_name")}>
            <div className="flex items-center space-x-1">
              <span>University </span>
              {getSortIcon("university_name")}
            </div>
          </div>
          <div onClick={() => handleSort('created_on')}>
          <div className='flex items-center space-x-1'>
              <span> Created On</span>
              {getSortIcon('created_on')}
            </div>
              
            </div>
            <div onClick={() => handleSort('created_by')} >
            <div className='flex items-center space-x-1'>
              <span> Created By</span>
              {getSortIcon('created_by')}
            </div>
            </div>
          <div>Course Description</div>
          <div>Action</div>
        </div>

        {/* Table Body */}
        {filteredEntries.length > 0 ? (
          filteredEntries.map((course, index) => {
            const plainTextDetails = htmlToText(course.course_details, {
              wordwrap: 130,
            });
            return (
              <div
                key={index}
                className="grid grid-cols-8 gap-2  text-tableTextColor text-sm border-b border-gray-200 p-3 items-center hover:bg-gray-100"
              >
                <div>{index + 1}</div>
                <div className="">{course.course_master_name}</div>
                <div>{course.program_master_name}</div>
                <div>{course.university_name}</div>
                <div>{course.created_on ? course.created_on.split('T')[0] : 'N/A'}</div>
              <div>{course.user_name}</div>
                <div className="truncate">
                  <p className="line-clamp-2">{plainTextDetails}</p>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() =>
                      navigate(`/admin/courses/edit/${course.id}`, {
                        state: course,
                      })
                    }
                    className="bg-cmsgreen hover:bg-hovergreen text-white p-2 rounded"
                  >
                    <HiPencilAlt />
                  </button>
                  <button
                    className="bg-red-500 hover:bg-red-700 text-white p-2 rounded"
                    onClick={() => handleDelete(course.id)}
                  >
                    <HiTrash />
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-center py-4">No courses found</div>
        )}

        {/* Pagination */}
        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center">
            <span className="text-sm text-gray-600 mr-2">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className="border border-gray-300 rounded"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
            </select>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={handlePrevious}
              className="px-4 py-2 bg-cmsgreen text-white rounded-md disabled:bg-hovergreen text-sm"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className="px-4 py-2 bg-cmsgreen text-white rounded-md disabled:bg-hovergreen text-sm"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AllCourses;
