import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import HeaderSection from '../../../dashboard/sidebarpages/headerSection/HeaderSection';
import { Base_URL } from '../../../apiConfig';
import { useSelector } from 'react-redux';

const SponsoredAdd = () => {
  const [universities, setUniversities] = useState([]);
  const [formData, setFormData] = useState({
    university_id: '',
    college_url: '',
    date: '',
    end_duration: '',
    is_active: '1', // Default to active
    total_count: '',
    page: 'college_page',
    image: null
  });
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD

  const pageOptions = [
    { value: 'college_page', label: 'College Page' },
    { value: 'university_page', label: 'All University Page' },
    { value: 'landing_page', label: 'Landing Page' }
  ];

  // Fetch universities
  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        const response = await axios.get(
          `${Base_URL}/get-values-array/universities`
        );
        const universityOptions = response.data.map((university) => ({
          value: university[0],
          label: university[1],
        }));
        setUniversities(universityOptions);
      } catch (error) {
        toast.error("Failed to fetch universities");
        console.error("Failed to fetch universities:", error);
      }
    };
    
    fetchUniversities();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // Special handling for is_active
    if (name === 'is_active') {
      setFormData(prev => ({
        ...prev,
        [name]: value === 'active' ? '1' : '0'
      }));
    } 
    // Special handling for date fields: ensure end_duration is not before date
    else if (name === 'date') {
      const newStartDate = value;
      setFormData(prev => {
        // If end date exists and is before new start date, reset end date
        if (prev.end_duration && prev.end_duration < newStartDate) {
          return {
            ...prev,
            [name]: value,
            end_duration: '' // Reset end date if it's before new start date
          };
        }
        return {
          ...prev,
          [name]: value
        };
      });
    }
    else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     // Check file size (max 5MB)
  //     if (file.size > 5 * 1024 * 1024) {
  //       toast.error('File size should be less than 5MB');
  //       return;
  //     }

  //     // Check dimensions for images
  //     if (file.type.startsWith('image/')) {
  //       const img = new Image();
  //       img.onload = function() {
  //         if (this.width > 1920 || this.height > 1080) {
  //           toast.error('Image dimensions should be max 1920x1080 pixels');
  //           return;
  //         }
  //         setFormData(prev => ({
  //           ...prev,
  //           image: file
  //         }));
  //       };
  //       img.src = URL.createObjectURL(file);
  //     } else {
  //       setFormData(prev => ({
  //         ...prev,
  //         image: file
  //       }));
  //     }
  //   }
  // };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Simply set the file without size validation
      setFormData(prev => ({
        ...prev,
        image: file
      }));
    }
  };
  // Function to validate URL
  const isValidURL = (url) => {
    try {
      // Use URL constructor for validation
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Validate all required fields
    if (!formData.university_id) {
      toast.error('Please select a university');
      return;
    }
    
    if (!formData.college_url) {
      toast.error('Please enter a college URL');
      return;
    }
    
    // Validate URL format
    if (!isValidURL(formData.college_url)) {
      toast.error('Please enter a valid URL (e.g., https://example.com)');
      return;
    }
    
    if (!formData.date) {
      toast.error('Please select a start date');
      return;
    }
    
    if (!formData.end_duration) {
      toast.error('Please select an end duration');
      return;
    }
    
    // Modified validation: Allow end date to be the same as start date
    if (formData.end_duration < formData.date) {
      toast.error('End date must be on or after start date');
      return;
    }
    
    if (!formData.total_count) {
      toast.error('Please enter a total count');
      return;
    }
    
    // Validate the count is greater than 0
    if (parseInt(formData.total_count) <= 0) {
      toast.error('Total count must be greater than 0');
      return;
    }
    
    if (!formData.image) {
      toast.error('Please select an image or GIF');
      return;
    }

    setIsLoading(true);

    try {
      const submitFormData = new FormData();
      Object.keys(formData).forEach(key => {
        submitFormData.append(key, formData[key]);
      });

      const response = await axios.post(`${Base_URL}/sponsor-ads`, submitFormData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });

      // Show success message from backend if available
      if (response.data?.message) {
        toast.success(response.data.message);
      } else {
        toast.success('Sponsor ad created successfully!');
      }
      
      // Reset form
      setFormData({
        university_id: '',
        college_url: '',
        date: '',
        end_duration: '',
        is_active: '1',
        total_count: '',
        page: 'college_page',
        image: null
      });
      
      // Reset file input
      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) {
        fileInput.value = '';
      }
      
    } catch (error) {
      console.error('Error submitting sponsor ad:', error);
      // Display the exact error message from the backend
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to create sponsor ad');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <HeaderSection title="Create Sponsor Ad" breadcrumb={['Sponsor Ads', 'Create Sponsor Ad']} />
      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
          <div className="font-poppins text-[#312a2a] text-lg font-semibold">
            Create Sponsor Ad
          </div>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Page Selection */}
            <div className="flex flex-col">
              <label className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">
                Select Page <span className="text-red-500">*</span>
              </label>
              <select
                name="page"
                value={formData.page}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md p-2 mt-2"
                required
              >
                {pageOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            {/* University Selection */}
            <div className="flex flex-col">
              <label className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">
                Select University <span className="text-red-500">*</span>
              </label>
              <select
                name="university_id"
                value={formData.university_id}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md p-2 mt-2"
                required
              >
                <option value="">-- Select University --</option>
                {universities.map((uni) => (
                  <option key={uni.value} value={uni.value}>
                    {uni.label}
                  </option>
                ))}
              </select>
            </div>

            {/* College URL */}
            <div className="flex flex-col">
              <label className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">
                College URL <span className="text-red-500">*</span>
              </label>
              <input
                type="url"
                name="college_url"
                value={formData.college_url}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md p-2 mt-2"
                placeholder="https://example.com"
                pattern="https?://.*"
                required
              />
              <p className="text-sm text-gray-500 mt-1">
                Please enter a valid URL (e.g., https://example.com)
              </p>
            </div>

            {/* Start Date */}
            <div className="flex flex-col">
              <label className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">
                Start Date <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md p-2 mt-2"
                min={today}
                required
              />
              <p className="text-sm text-gray-500 mt-1">
                Only present and future dates allowed
              </p>
            </div>

            {/* End Duration */}
            <div className="flex flex-col">
              <label className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">
                End Duration <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                name="end_duration"
                value={formData.end_duration}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md p-2 mt-2"
                min={formData.date || today}
                required
                disabled={!formData.date}
              />
              <p className="text-sm text-gray-500 mt-1">
                {!formData.date 
                  ? "Select start date first" 
                  : "Must be on or after start date"}
              </p>
            </div>

            {/* Total Count */}
            <div className="flex flex-col">
              <label className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">
                Total Count <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                name="total_count"
                value={formData.total_count}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md p-2 mt-2"
                placeholder="Enter total count"
                min="1"
                required
              />
              <p className="text-sm text-gray-500 mt-1">
                Value must be greater than 0
              </p>
            </div>

            {/* Active Status */}
            <div className="flex flex-col">
              <label className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">
                Status <span className="text-red-500">*</span>
              </label>
              <select
                name="is_active"
                value={formData.is_active === '1' ? 'active' : 'inactive'}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md p-2 mt-2"
                required
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>

            {/* Image Upload */}
            <div className="flex flex-col">
              <label className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">
                Upload Image/GIF <span className="text-red-500">*</span>
              </label>
              <input
                type="file"
                accept="image/*,.gif"
                onChange={handleFileChange}
                className="border border-gray-300 rounded-md p-2 mt-2"
                required
              />
              <p className="text-sm text-gray-500 mt-1">
                 4616 x 704
              </p>
            </div>
          </div>

          {/* Submit Button */}
          <button
              type="submit"
            className="mt-6 px-4 py-2 bg-cmsgreen hover:bg-hovergreen text-white rounded-md flex items-center justify-center w-full md:w-auto"
            disabled={isLoading}
          >
            {isLoading ? 'Creating...' : 'Create Sponsor Ad'}
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default SponsoredAdd;