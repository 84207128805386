import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Base_URL } from "../apiConfig";
import { useSelector } from "react-redux";

const CareerBranchSubContent = () => {
  const { title } = useParams();
  const [matchedContent, setMatchedContent] = useState(null);
  const [careerData, setCareerData] = useState([]);
  const [programTitle, setProgramTitle] = useState("Program");
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const isDashboard = location.pathname === '/student-dashboard';

  useEffect(() => {
    if (isDashboard) {
      // Fetch from /student/career for dashboard view
      const fetchCareerData = async () => {
        try {
          const response = await axios.get(
            `${Base_URL}/student/career`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("Career Data Response:", response.data);
          setCareerData(response.data);
        } catch (error) {
          console.error("Error fetching career data:", error);
        }
      };
      fetchCareerData();
    } else if (title) {
      // Fetch from branch-sub-content for normal view
      const fetchBranchContent = async () => {
        try {
          const decodedBranchTitle = title.replace(/-/g, " ");
          console.log("Decoded Branch Title:", decodedBranchTitle);

          const response = await axios.get(
            `${Base_URL}/branch-sub-content/branch-content/${decodedBranchTitle}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          console.log("API Response:", response.data);
          const data = response.data;

          if (data && data.length > 0) {
            setMatchedContent(data[0]);
            setProgramTitle(data[0].title);
          } else {
            setMatchedContent(null);
            setProgramTitle("Program");
          }
        } catch (error) {
          console.error("Error fetching branch content:", error);
        }
      };
      fetchBranchContent();
    }
  }, [title, token, isDashboard]);

  const handleNavigate = async (contentTitle, contentId) => {
    // Format the title for the URL first
    const formattedTitle = contentTitle.trim().replace(/\s+/g, "-").toLowerCase();
    
    try {
      // Try to post the preference
      await axios.post(
        `${Base_URL}/student/career/career-preference`,
        { branch_sub_content_id: contentId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error posting preference:", error);
    } finally {
      // Navigate regardless of API success or failure
      navigate(`/career/${formattedTitle}/details`);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow p-6 mt-20">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold mb-6 font-dm-sans mt-10 text-center">
            {isDashboard ? "Explore Career Options" : `Explore Branch Sub Content for ${programTitle}`}
          </h2>
          <div className="grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-20">
            {isDashboard ? (
              // Dashboard view showing career data
              careerData.length > 0 ? (
                careerData.map((career) => (
                  <div
                    key={career.id}
                    className="border-[#75E36B] bg-white text-black shadow-md rounded-[10px] h-[60px] relative flex flex-col hover:bg-[#D9D9D9] hover:border-[#75E36B] hover:text-black transition-all duration-300"
                  >
                    <button
                      className="w-full h-[60px] bg-transparent text-black font-bold rounded-[10px] border border-[#75E36B] hover:bg-[#75E36B] hover:text-white transition-all ease-out duration-300 text-sm text-center break-words px-2"
                      onClick={() => handleNavigate(career.title, career.id)}
                    >
                      {career.title}
                    </button>
                  </div>
                ))
              ) : (
                <p className="col-span-4 text-center">No career options found.</p>
              )
            ) : (
              // Normal view showing matched content
              matchedContent ? (
                <div
                  key={matchedContent.id}
                  className="border-[#75E36B] bg-white text-black shadow-md rounded-[10px] h-[60px] relative flex flex-col hover:bg-[#D9D9D9] hover:border-[#75E36B] hover:text-black transition-all duration-300"
                >
                  <button
                    className="w-full h-[60px] bg-transparent text-black font-bold rounded-[10px] border border-[#75E36B] hover:bg-[#75E36B] hover:text-white transition-all ease-out duration-300 text-sm text-center break-words px-2"
                    onClick={() => handleNavigate(matchedContent.title, matchedContent.id)}
                  >
                    {matchedContent.title}
                  </button>
                </div>
              ) : (
                <p className="col-span-4 text-center">No content found for this program.</p>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerBranchSubContent;
