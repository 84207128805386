import apiClient from "../apiClient"; // Import the Axios instance with interceptors
import { API_ENDPOINT } from "../../utils/constant";
import { useSelector } from "react-redux";



const CollegeListService = {


  /**
   * Fetch featured universities.
   * @returns {Promise<Array>} List of featured universities.
   */
  FeaturedColleges: async () => {
    try {
      const response = await apiClient.get(API_ENDPOINT.FEATURED_COLLEGES);
      const processedData = response.data.map((item) => ({
        ...item,
        pictures: JSON.parse(item.pictures),
      }));
      const activeColleges = processedData.filter(
        (college) => college.is_active === 1
      );
      console.log('featured',activeColleges)
      return activeColleges;
    } catch (error) {
      console.error("Error fetching featured universities:", error.message);
      throw new Error(
        error.response?.data?.message || "Unable to fetch featured universities"
      );
    }
  },

  /**
   * Fetch all universities.
   * @returns {Promise<Array>} List of all universities.
   */
  AllColleges: async () => {
    try {
      const response = await apiClient.get(API_ENDPOINT.ALL_COLLEGES);
      const processedData = response.data.map((item) => ({
        ...item,
        pictures: JSON.parse(item.pictures),
      }));
      const activeColleges = processedData.filter(
        (college) => college.is_active === 1
      );
      return activeColleges;
    } catch (error) {
      console.error("Error fetching all universities:", error.message);
      throw new Error(
        error.response?.data?.message || "Unable to fetch all universities"
      );
    }
  },

  /**
   * Fetch popular universities.
   * @returns {Promise<Array>} List of popular universities.
   */
  PopularColleges: async () => {
    try {
      const response = await apiClient.get(API_ENDPOINT.POPULAR_COLLEGES);
      const processedData = response.data.map((item) => ({
        ...item,
        pictures: JSON.parse(item.pictures),
      }));

      const activeColleges = processedData.filter(
        (college) => college.is_active === 1
      )
      return activeColleges
    } catch (error) {
      console.error("Error fetching popular universities:", error.message);
      throw new Error(
        error.response?.data?.message || "Unable to fetch popular universities"
      );
    }
  },

  /**
   * Fetch college details by name.
   * @param {string} collegeName - The name of the college (URL-friendly).
   * @returns {Promise<Object>} College details.
   */
  FetchCollegeByName: async (collegeName) => {
    try {
      // Replace hyphens with spaces to format the name
      const formattedName = collegeName.replace(/-/g, " ");

      const response = await apiClient.get(
        `${API_ENDPOINT.COLLEGE_BY_NAME}/${formattedName}`
      );
      if (Array.isArray(response.data)) {
        const processedData = response.data.map((item) => ({
          ...item,
          pictures: JSON.parse(item.pictures),
        }));
        
         const activeColleges = processedData.filter(
        (college) => college.is_active === 1
      );
      return processedData
        // Parse pictures field if it's JSON stringified
      } else {
        const processedData = [];
        return processedData;
      }
    } catch (error) {
      console.error(
        `Error fetching details for college: ${collegeName}`,
        error.message
      );
      throw new Error(
        error.response?.data?.message || "Unable to fetch college details"
      );
    }
  },
};

export default CollegeListService;
